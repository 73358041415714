<template>
  <div class="g-content" v-if="showPanel">
    <PublicHeader class="header" />

    <div class="middle">
      <div class="ct-l">
        <PublicLeft class="left" />
      </div>
      <div class="ct">
        <PublicTabs></PublicTabs>
        <div style="height: 100%;overflow-y: auto;">
          <div class="nav-path" v-show="(!isMiniScreen || !showMainMenu) && navPathName">
            <div class="main-txt">{{ navPathName }}</div>
            <div class="extra-txt">{{ $t('g.ms') }}</div>
            <!-- <el-tabs
              v-model="editableTabsValue"
              type="card"
            
              closable
              @tab-remove="removeTab"
            >
              <el-tab-pane
                v-for="item in editableTabs"
                :key="item.name"
                :label="item.title"
                :name="item.name"
              >
              </el-tab-pane>
            </el-tabs> -->
          </div>
          <!-- <router-view class="main" v-show="!isMiniScreen || !showMainMenu"/> -->
          <!-- {{ $route }} -->
          <router-view class="main" v-slot="{ Component }">
            <keep-alive>
              <component :is="Component" :key="$route.path" v-if="$route.meta.keepAlive"
                v-show="!isMiniScreen || !showMainMenu" />
            </keep-alive>
            <component :is="Component" :key="$route.path" v-if="!$route.meta.keepAlive"
              v-show="!isMiniScreen || !showMainMenu" />
          </router-view>
        </div>

      </div>
    </div>
    <PublicFooter class="footer" />
  </div>
  <router-view class="full" v-else />
</template>
<script>
import PublicHeader from '@/components/public/header.vue'
import PublicLeft from '@/components/public/left.vue'
import PublicFooter from '@/components/public/footer.vue'
import PublicTabs from '@/components/public/tabs.vue'
import { showPanel, showMainMenu, isMiniScreen, updateOperators, updatePile, updateCombo } from '@/data/default'
import { findTitleByNavName } from '@/data/menu'
import { useRoute } from 'vue-router'
import { onMounted, watch, ref } from 'vue'
export default {
  components: {
    PublicHeader,
    PublicLeft,
    PublicFooter,
    PublicTabs
  },
  setup() {
    const route = useRoute()
    const navPathName = ref('')
    const editableTabsValue = ref('2')
    const editableTabs = ref([
      {
        title: '电池列表',
        name: '1',
        content: 'Tab 1 content',
      },
      {
        title: '会员中心',
        name: '2',
        content: 'Tab 2 content',
      },
    ])
    watch(() => route.name, (newName) => {
      console.log(newName)
      // console.log("new route name",newName,findTitleByNavName(newName))
      navPathName.value = findTitleByNavName(newName)
    }, { immediate: true, deep: true })
    onMounted(() => {
      try {
        const loading = document.getElementById('g-loading')
        loading.remove()
      } catch (e) {

      }
      updateOperators()
      updatePile()
      updateCombo()
    })
    return {
      showPanel,
      showMainMenu,
      isMiniScreen,
      navPathName,
      editableTabsValue,
      editableTabs,
    }
  }
}
</script>
<style lang="scss">
.nav-path {
  display: flex;
  align-items: flex-end;
  margin: 0 1.25rem;
  // margin-top: 1.25rem;
  border-bottom: 1px solid #ccc;
  padding-bottom: 0.25rem;
  box-sizing: border-box;

  .main-txt {
    font-size: 1.8rem;
    font-weight: 700;
    margin-right: 1rem;
  }

  .extra-txt {}
}

.g-content {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  background-color: #f2f6f9;
}

.header {}

.middle {
  display: flex;
  flex: 1;
  overflow: hidden;

  .left {}

  .ct-l {
    height: calc(100% - 3rem);
    overflow-y: scroll;
  }

  .ct {
    flex: 1;
    height: 100%;
    overflow-y: hidden;
  }

  .main {}
}

.footer {}
</style>
<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  // font-size: 1.171vw;//自适应Rem
  /*
    1 rem = 16px = x vw = 16px / 1366px * 100
    x = 1.1713
  */
}

#app {
  // font-family: 微软雅黑, Avenir, Helvetica, Arial, sans-serif; 
  font-family: 微软雅黑;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.clusterBubble {
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  text-align: center;
  opacity: 0.88;
  background-image: linear-gradient(139deg, #4294FF 0%, #295BFF 100%);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.20);
  position: absolute;
  top: 0px;
  left: 0px;
}

.table-expand {
  >.devimg {
    display: flex;
    align-items: center;

    >.img-item {
      display: flex;
      align-items: center;
      margin-right: 1.5rem;

      >.label {
        margin-right: 0.5rem;
      }

      >.img {
        width: 8rem;
        height: 5rem;
      }
    }
  }
}

@media screen and (max-width:800px) {
  .content {
    >.card-box {
      >.inp-list {
        >.item {
          width: 100% !important;
        }
      }
    }
  }
}

//  .el-cascader-panel .el-radio{
//  position:absolute;
//  z-index:10;
//  padding:0 10px;
//  width:100%;
//  height:34px;
//  line-height:34px;
// }
// .el-cascader-panel .el-radio__input{
//  visibility:hidden;
// }
//  .el-cascader-panel .el-input-node__postfix{
//  top:10px;
// }
.el-cascader-menu__wrap .el-radio__inner {
  width: 25px !important;
  height: 25px !important;
}

.el-select {
  width: 180px !important;
}
</style>

<template>
  <div class="content">
    <div class="card-box data-table">
      <div class="header">
        <el-button class="add_btn" @click="func.add()" type="primary"  :icon="Plus">新增</el-button>
        <!-- <div class="header-icons"> -->
          <!-- <i @click="func.loadData()" class="el-icon-download header-icon"></i> -->
          <!-- <el-icon @click="func.add()" class="el-icon-plus header-icon">
            <Plus />
          </el-icon> -->
          
        <!-- </div> -->
      </div>
      <el-table :data="formData.tableData" style="width: 100%">
        <el-table-column prop="Id" label="#" width="80">
        </el-table-column>
        <el-table-column prop="Name" :label="$t('rights.name')" width="200">
        </el-table-column>
        <el-table-column prop="Username" :label="$t('rights.username')" width="200">
        </el-table-column>
        <el-table-column prop="RoleName" min-width="160" :label="$t('rights.role')">
        </el-table-column>
        <el-table-column
          width="160"
          label="编码">
          <template #default="scope">
            <span>{{ scope.row.AgentCode!=''?scope.row.AgentCode:'平台' }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="AgentLevel" min-width="160" label="等级">
        </el-table-column> -->
        <el-table-column prop="NamePath" min-width="160" label="账号归属">
        </el-table-column>
        <el-table-column prop="ExpireTimeStr" min-width="160" :label="$t('card.expire')">
        </el-table-column>
        <el-table-column width="160" :label="$t('g.operation')" fixed="right">
          <template #default="scope">
            <el-tag @click="func.edit(scope.row)">{{ $t('g.edit') }}</el-tag>
            <el-tag class="btn-del" @click="func.del(scope.row)" type="warning">{{ $t('g.delete') }}</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :title="$t('tips')" v-model="formData.dialogVisible" width="80%">
      <el-form label-width="120px" :model="formData.curData">
        <div class="el-form-row">
          <el-form-item :label="$t('rights.username')">
            <el-input v-model="formData.curData.Username"></el-input>
          </el-form-item>
          <el-form-item :label="$t('rights.password')">
            <!-- :placeholder="$t('rights.ph_psw')" -->
            <el-input  v-model="formData.curData.NewPwd" type="password"></el-input>
          </el-form-item>
          <el-form-item :label="$t('rights.name')">
            <el-input v-model="formData.curData.Name"></el-input>
          </el-form-item>
        </div>
        <div class="el-form-row">
          <!-- $t('rights.department') -->
          <el-form-item label="所属角色">
            <el-select class="inp2" v-model="formData.curData.RoleId" :placeholder="$t('g.choose')">
              <el-option v-for="item in formData.roles" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('member.mobile')">
            <el-input v-model="formData.curData.Mobile"></el-input>
          </el-form-item>
          <el-form-item :label="$t('member.email')">
            <el-input v-model="formData.curData.Email"></el-input>
          </el-form-item>
        </div>

        <div class="el-form-row">
          <!-- <el-form-item :label="$t('rights.agent')">
            <el-select class="inp2" v-model="formData.curData.AgentCode" :placeholder="$t('g.choose')">
              <el-option v-for="item in render.operators" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item :label="$t('rights.agent')" >
            <el-cascader  style="width: 100%;" :clearable="true" v-model="formData.curData.AgentCode" :props="formData.props" :options="render.options" />
          </el-form-item>
          <el-form-item label="运营商" width="350px" >
              <el-cascader  style="width: 100%;flex: 1;" :clearable="true" v-model="formData.curData.CodePath"
                :props="formData.props2" :options="render.options" />
            </el-form-item>
          <el-form-item :label="$t('device.type')">
            <el-select v-model="formData.curData.DevType" placeholder="Select" multiple>
              <el-option v-for="item in formData.devType" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          
        </div>
        <div class="el-form-row">
          <el-form-item :label="$t('rights.effective_time')">
            <el-date-picker v-model="formData.expireTime" type="date" :placeholder="$t('rights.choose_time')"
              :disabled-date="disabledDate">
            </el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('rights.is_mch')" v-if="formData.curData.RoleId == formData.OPERATOR_ROLE_ID">
            <el-checkbox class="cb" v-model="formData.valIsMch"></el-checkbox>
          </el-form-item>
        </div>
        <div class="el-form-row el-form-row-left">
          <el-form-item :label="$t('device.site_name')">
            <el-select v-model="formData.curData.SiteName" placeholder="Select" multiple>
              <el-option v-for="item in formData.station" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('rights.dev_ids')">
            <el-select v-model="formData.curData.DevIds" placeholder="Select" multiple style="width:500px">
              <el-option v-for="item in formData.devs" :key="item.Id" :label="item.DevId"
                :value="item.DevId.toString()">
                <span style="float: left;margin: 0 20px;">{{ item.DevId }}</span>
                <span style="margin: 0 20px;width:200px">{{ item.Address }}</span>
                <span style="
                float: right;
                color: var(--el-text-color-secondary);
                font-size: 13px;
              ">{{ item.SiteName }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="default" @click="formData.dialogVisible = false">{{ $t('g.btn_cancel') }}</el-button>
          <el-button type="primary" @click="func.save()">{{ $t('g.btn_confirm') }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { nextTick, ref, watch } from 'vue';
import { reactive, onMounted } from 'vue'
import axios from 'axios'
import { operators, shortcuts, OPERATOR_ROLE_ID, SITE_MANAGER_ROLE_ID } from "@/data/default"
import { toDay } from "@/common/func"
import i18n from '@/data/i18n/index'
import { ElMessageBox } from 'element-plus'
import { user } from '@/data/token'
import { ElMessage } from 'element-plus'
import moment from 'moment'
import { Plus } from '@element-plus/icons-vue'
import { isOperator } from '@/data/token'
import qs from 'qs'
export default {
  name: 'RightsManager',
  component: {
  },
  setup() {
    const effectiveDate = ref(null);

    const disabledDate = (date) => {
      return date < new Date();
    };
    console.log("user", user)
    const t = i18n.global.t
    const tableData = [
    ]
    const render = reactive({
      shortcuts,
      operators,
      options:[]
    })
    const formData = reactive({
      SITE_MANAGER_ROLE_ID,
      OPERATOR_ROLE_ID,
      tableData,
      curData: {},
      valTime: '',
      valDevId: '',
      valName: '',
      valIsMch: false,
      dialogVisible: false,
      curPage: 1,
      pageSize: 10,
      total: 0,
      roles: [],
      valAddress: '',
      expireTime: 0,
      devs: [],
      station: [],
      devType: [],
      devDisableed: [],//devId,type,station
      props:{
          children:"Children",expandTrigger: 'hover',
          label:"Name",
          value:"Code",
          emitPath:false,
          checkStrictly:true
      },
      props2:{
          children:"Children",expandTrigger: 'hover',
          label:"Name",
          value:"CodePath",
          emitPath:false,
          checkStrictly:true
      },
      user_info:{}
    })
    watch(() => formData.curData.AgentCode, () => {
      func.getDevs(formData.curData.AgentCode)
      func.getStation(formData.curData.AgentCode)
      //func.getDevType(formData.curData.AgentCode)
    })
    watch(() => formData.curData.CodePath, () => {
      func.getDevType(formData.curData.CodePath)
    })
    watch(() => formData.curData.DevType, () => {
      if (formData.curData.DevType && formData.curData.DevType.length > 0) {
        let type = formData.curData.DevType.join('|')
        func.getStation(formData.curData.AgentCode, type)
        func.getDevs(formData.curData.AgentCode, type)
      }

    })
    const func = {
      search() {
        console.log('formData', formData)
        func.loadData()
      },
      edit(row) {
        formData.curData = Object.assign({}, row)
        console.log(JSON.stringify(formData.curData), 23);
        formData.curData.AgentCode = row.AgentCode 
        formData.curData.CodePath = row.CodePath 
        console.log(row.AgentCode,'修改',render.options)
        // if (formData.curData.DevIds) {
        //   formData.curData.DevIds = formData.curData.DevIds.split(',')
        // }
        if (formData.curData.SiteName && formData.curData.SiteName.length > 0) {
          // console.log(JSON.stringify(formData.curData.SiteName));
          formData.curData.SiteName = formData.curData.SiteName[0].split('|')
        }
        // console.log(JSON.stringify(formData.curData)); 

        formData.dialogVisible = true
        formData.expireTime = toDay(formData.curData.ExpireTime)
      },
      async del(row) {
        try {
          await ElMessageBox.confirm(
            t('g.sure_delete'),
            t('tips'),
            {
              confirmButtonText: t('confirm'),
              cancelButtonText: t('cancel'),
              type: 'warning',
            }
          )
          await axios.post("/admin/manager/delete", {
            id: row.Id
          })
          func.loadData()
        } catch (e) {
          console.log("del", e)
        }
      },
      async get_options(){
          const params = {
              level:3,
          }
          let res = await axios.get(`/admin/agent/buildtree`,{params})
          render.options = res
      },
      async save() {
        const data = formData.curData
        console.log(JSON.stringify(data), 2803);
        if(!data.RoleId){
          alert('请选择角色')
          return
        }
        if(formData.user_info.RoleId != 14&&!formData.curData.AgentCode){
          alert('请选择代理商')
          return
        }
        let params = {
          role_id: data.RoleId,
          username: data.Username,
          name: data.Name,
          expire_time: moment(formData.expireTime).valueOf() / 1000,
        }
        
        // if (data.AgentCode) {
        //   params.agentCode = data.AgentCode
        // }
          params.agentCode = data.AgentCode == 0 ? '' : data.AgentCode   
          params.CodePath = data.CodePath

        if (data.DevType && data.DevType.length > 0) {
          params.devType = data.DevType.join('|')
        }
        if (data.SiteName && data.SiteName.length > 0) {
          params.siteName = data.SiteName.join('|')
        }
        if (data.Mobile) {
          params.mobile = data.Mobile
        }
        if (data.Email) {
          params.email = data.Email
        }
        if (data.DevIds && data.DevIds.length > 0) {
          params.devIds = data.DevIds.join()
        }
        // if(data.IsDevManager){
        //   params.isDevManager = data.IsDevManager
        // }
        // if(data.IsMch){
        //   params.isMch = data.IsMch
        // }
        console.log(JSON.stringify(params));

        console.log(moment(params.expire_time).valueOf() / 1000)
        if (data.NewPwd) {
          params.password = data.NewPwd
        }
        if (!params.role_id) {
          return ElMessage({
            type: 'error',
            message: t("rights.please_choose_deparment")
          })
        }
        if (params.role_id == formData.SITE_MANAGER_ROLE_ID) {
          params.role_id = 0
          params.devIds = data.DevIds
          params.isDevManager = 1
        }
        if (params.role_id == formData.OPERATOR_ROLE_ID) {
          params.isMch = formData.valIsMch ? 1 : 0
        }
        // return
        console.log(qs.stringify(params));

        if (data.Id) {
          params.id = data.Id
          await axios.post("/admin/manager/doEdit", params)
        } else {
          await axios.post("/admin/manager/doAdd", params)
        }
        func.loadData()
        formData.dialogVisible = false
      },
      async getDevs(agent, type) {
        let params = {}
        params.code = agent
        if (type) {
          params.devType = type
        }
        params.getAll = 1
        let res = await axios.get('/admin/box', { params })
        if (res) {
          await nextTick(() => {
            formData.devs = res.List
          })
        }
      },
      async getDevType(CodePath) {
        let params = {}
        params.CodePath = CodePath
        params.getAll = 1
        let res = await axios.get('/admin/box/devtypes', { params })
        if (res) {
          await nextTick(() => {
            formData.devType = res
            console.log(JSON.stringify(formData.station), 82038);

          })
        }
      },
      async getStation(agent, type) {
        let params = {}
        params.agentCode = agent
        if (type) {
          params.devType = type
        }
        params.getAll = 1
        let res = await axios.get('/admin/box/sitenames', { params })
        if (res) {
          await nextTick(() => {
            formData.station = res
            console.log(JSON.stringify(formData.station), 82038);

          })
        }
      },
      add() {
        formData.curData = {}
        formData.dialogVisible = true
      },
      rowDblClick(row, column, e) {
        console.log('rowDblClick', row, column, e)
        formData.curData = row
        formData.dialogVisible = true
      },
      pageChange(val) {
        console.log('page change', val)
        func.loadData(val)
      },
      async loadRoles() {
        //?add=1
        let res = await axios.get("/admin/role?getAll=1")
        const roles = [{
          label: t('g.choose'),
          value: 0
        }]
        for (let item of res) {
          roles.push({
            label: item.Title,
            value: item.Id,
          })
        }
        formData.roles = roles
      },
      async loadData(page = 1) {
        // func.getDevs()
        func.loadRoles()
        try {
          formData.tableData = []
          formData.curPage = page
          let res = await axios.get(`/admin/manager`, {
            params: {}
          })
          res.map((item) => {
            if (item.IsDevManager == 1) {
              item.RoleId = formData.SITE_MANAGER_ROLE_ID
              item.RoleName = t('rights.site_manager')
            } else {
              item.RoleName = item.Role.Id ? item.Role.Title : t('g.null')
            }
            item.ExpireTimeStr = item.ExpireTime == 0 ? '永久' : toDay(item.ExpireTime)
          })
          formData.tableData = res
        } catch (e) {
          console.log("loadData", e)
        }
      }
    }
    onMounted(() => {
      func.loadData()
      func.get_options()
      if(localStorage.getItem('user_info')){
        formData.user_info = JSON.parse(localStorage.getItem('user_info'))
      }
    })
    return {
      user,
      func,
      render,
      formData,
      effectiveDate,
      disabledDate,
      Plus,
      isOperator
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;

  .inp2 {
    width: 100%;
  }

  .btn-del {
    margin-left: 0.5rem;
  }

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: right;
      margin-bottom: 1.25rem;
      position: relative;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }

      >.header-icons {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;

        .header-icon {
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color: #969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    .inp-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;

      >.item {
        width: 49%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;

        .label {
          margin-right: 0.625rem;
          width: 4.375rem;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }
  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  >.data-table {
    :deep(.el-table) {
      .cell {
        white-space: normal;
        text-overflow: ellipsis;
      }
      .el-table__row {
        cursor: pointer;
      }
    }
  }

  >.pagination {
    text-align: right;
    margin-top: 1.25rem;
  }

  .el-form-row {
    display: flex;
    justify-content: space-between;

    &.el-form-row-right {
      justify-content: space-around;
    }

    :deep(.el-form-item) {
      width: 49%;
    }
  }

  :deep(.m-dialog) {
    max-width: 50rem;
  }
}

.add_btn{
  padding-bottom: 10px;
}
@media screen and (max-width:800px) {
  .content {
    >.card-box {
      .inp-list {
        >.item {
          width: 100%;
        }
      }
    }
  }
}
</style>

import { ref, reactive } from 'vue'
import axios from "axios"
import i18n from '@/data/i18n/index'
const t = i18n.global.t
// 是否展示公共组件
export const showPanel = ref(true)
export const updateShowPanel = (flag) => {
  if (showPanel.value === flag) {
    return
  }
  showPanel.value = flag
}
// 是否是小屏幕(样式适配)
const screenWidth = document.body.clientWidth
// console.log('screen width', screenWidth)
export const isMiniScreen = ref(screenWidth <= 800)
// 是否展示左侧菜单
export const showMainMenu = ref(!isMiniScreen.value)
export const toggleMainMenu = () => {
  showMainMenu.value = !showMainMenu.value
}

const getCoordinate = ()=>{
  const t = i18n.global.t
  return new Promise((res,rej)=>{
    if(navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            //locationSuccess 获取成功的话
            function(position) {
              // console.log("获取经纬度",position)
              res({lng:position.coords.longitude,lat:position.coords.latitude})
            },
            //locationError  获取失败的话
            function(error) {
                const errorType = [t('common.coord_errs.t0'), t('common.coord_errs.t1'), t('common.coord_errs.t2')];
                console.log(errorType[error.code - 1]);
                rej(errorType[error.code - 1]);
            }
        );
    }else{
      rej(t('common.not_support'))
    }
  })
}
let userCoord = {
  lng:0,
  lat:0
}
export const getCoord = async ()=>{
  try{
    if(!userCoord.lng){
      return {lng:114.057868,lat:22.543099}
      // userCoord = await getCoordinate()
    }
    return userCoord
  }catch(e){
    //默认位置
    console.log("getCoord err",e)
    return {lng:116.397563,lat:39.909897}
  }
}
getCoord()


// 运营商列表
export const operators = reactive([]) //有 ”全部“ 选项
export const operatorCascader = reactive([]) //级联
export const operatorList = reactive([])//没有 ”全部“ 选项


let operatorSrc = []
export const updateOperators = async () => {
  const newData = []
  const newData2 = []
  let newDataCascader = []
  try{
    const res = await axios.get("/admin/agent?getAll=1")
    operatorSrc = res
    for(let item of res.List){
      newData.push({
        levelname:item.LevelName,
        label:item.Name + ` ( ${item.Code} )`,
        value:item.Code,
        id:item.Id,
        parentId:item.ParentId
      })
      newData2.push({
        levelname:item.LevelName,
        label:item.Name + ` ( ${item.Code} )`,
        value:item.Id,
        id:item.Id,
        parentId:item.ParentId
      })
    }
    newDataCascader = arrayToTreeV3(newData2,0);

  }catch(e){
    console.log("get agent error",e)
  }
  operators.splice(0)
  if(newData.length !=1){
      operators.push({
        label:t('common.all'),
        value:0,
        id:0
      })
  }
  operators.push(...newData)
  operatorCascader.push(...newDataCascader)
  operatorList.splice(0)
  operatorList.push(...newData)
 
  //级联选择运营商
  cascade_options_direct.splice(0,cascade_options_direct.length)
  let data = await cascade_options()
  cascade_options_direct.push(...data)
  console.log(data)
}
export let cascade_options_direct = reactive([])
//获取运营商级联选择
export const cascade_options = async (level=3) => {
  const params = {
    level,
  }
  let res = await axios.get(`/admin/agent/buildtree`,{params})
  return new Promise((resolve,reject)=>{
    resolve(res);
  });
}


//柜充列表
export const pile = reactive([])

export const updatePile = async () =>{
    const newData = []
    let params = {
      type:3,
    }
    try{
      let res = await axios.get(`/admin/box`,{
        params
      })
      for(let item of res.List){
        newData.push({
          // label:item.Name,
          label:item.Name,
          value:item.Id,
        })
      }
    }catch(e){
      console.log("get agent error",e)
    }
    pile.splice(0)
    if(newData.length !=1){
        pile.push({
          label:t('common.all'),
          value:0
        })
    }
    pile.push(...newData)

}

//套餐列表

export const combo = reactive([])

export const updateCombo = async () =>{
  const newData = []
  let params = {}
  try{
    let res = await axios.get(`/admin/combo`,{
      params
    })
    for(let item of res.List){
      newData.push({
        // label:item.Name,
        label:item.Name,
        value:item.Id,
      })
    }
  }catch(e){
    console.log("get agent error",e)
  }
  combo.splice(0)
  if(newData.length !=1){
    combo.push({
        label:t('common.all'),
        value:0
      })
  }
  combo.push(...newData)

}


export const getOperatorName = (code) => {
  for(let item of res.List){
    if(item.Code == code){
      return item.Name
    }
  }
  return ""
}

//日期搜索快捷选项
export const shortcuts = [
  {
    text: t('common.dates.last_week'),
    value: () => {
      const end = new Date()
      end.setDate(end.getDate()+1)
      end.setHours(0)
      end.setMinutes(0)
      end.setSeconds(0)
      const start = new Date()
      start.setHours(0)
      start.setMinutes(0)
      start.setSeconds(0)
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      return [start, end]
    }
  }, {
    text: t('common.dates.last_month'),
    value: () => {
      const end = new Date()
      end.setDate(end.getDate()+1)
      end.setHours(0)
      end.setMinutes(0)
      end.setSeconds(0)
      const start = new Date()
      start.setHours(0)
      start.setMinutes(0)
      start.setSeconds(0)
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      return [start, end]
    }
  }, {
    text: t('common.dates.last_quarter'),
    value: () => {
      const end = new Date()
      end.setDate(end.getDate()+1)
      end.setHours(0)
      end.setMinutes(0)
      end.setSeconds(0)
      const start = new Date()
      start.setHours(0)
      start.setMinutes(0)
      start.setSeconds(0)
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      return [start, end]
    }
  }, {
    text: t('common.dates.future_week'),
    value: () => {
      const end = new Date()
      end.setHours(0)
      end.setMinutes(0)
      end.setSeconds(0)
      end.setTime(end.getTime() + 3600 * 1000 * 24 * 7)
      const start = new Date()
      start.setDate(start.getDate())
      start.setHours(0)
      start.setMinutes(0)
      start.setSeconds(0)
      return [start, end]
    }
  }, {
    text: t('common.dates.future_month'),
    value: () => {
      const end = new Date()
      end.setHours(0)
      end.setMinutes(0)
      end.setSeconds(0)
      end.setTime(end.getTime() + 3600 * 1000 * 24 * 30)
      const start = new Date()
      start.setDate(start.getDate())
      start.setHours(0)
      start.setMinutes(0)
      start.setSeconds(0)
      return [start, end]
    }
  }, {
    text: t('common.dates.future_quarter'),
    value: () => {
      const end = new Date()
      end.setHours(0)
      end.setMinutes(0)
      end.setSeconds(0)
      end.setTime(end.getTime() + 3600 * 1000 * 24 * 90)
      const start = new Date()
      start.setDate(start.getDate())
      start.setHours(0)
      start.setMinutes(0)
      start.setSeconds(0)
      return [start, end]
    }
  }
]

//运营商角色ID
export const OPERATOR_ROLE_ID = 18
//场站管理员角色ID
export const SITE_MANAGER_ROLE_ID = 16
//超级管理员角色ID
export const ADMIN_ROLE_ID = 14

function arrayToTreeV3(arr, root) {
  arr.forEach((item,index)=>{
      if(item.levelname&&item.levelname.lastIndexOf('/')!=-1){
        
          let ParentId = item.levelname.substr(0,item.levelname.lastIndexOf('/'))
          let find = arr.find((x)=>x.levelname==ParentId)
          arr[index].parentId = find.id
      }
  })
  return arr
      .filter(item => item.parentId === root  )
      .map(item => ({ ...item, children: arrayToTreeV3(arr, item.id) }))
}
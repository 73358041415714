<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">{{ $t("g.condition") }}</span>
      </div>
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t("g.create_at") }}</span>
          <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" size="small" type="daterange"
            unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
        </div>
        <!-- <div class="item" v-if="!isOperator()">
          <span class="label">{{ $t('g.operator') }}</span>
          <el-select class="inp" size="small" v-model="formData.valCode" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.operators" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div> -->
        
        <div class="item">
          <span class="label">{{ $t('openSlotsLit.dev_id') }}</span>
          <el-input class="inp" size="small" v-model="formData.valDevName"
            :placeholder="$t('openSlotsLit.dev_id')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('openSlotsLit.site_name') }}</span>
          <el-input class="inp" size="small" v-model="formData.valSiteName"
            :placeholder="$t('openSlotsLit.site_name')"></el-input>
        </div>
        <div class="item" >
          <span class="label">{{ $t('g.operator') }}</span>
          <el-cascader size="small"  style="width: 100%;" :clearable="true" v-model="formData.valCode" :props="formData.props" :options="render.cascade_options_direct" />
        </div>
        <div class="item">
          <span class="label">{{ $t('member.mobile') }}</span>
          <el-input class="inp" size="small" v-model="formData.valphone" :placeholder="$t('member.mobile')"></el-input>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.clear" class="search" type="info" size="small">{{ $t('g.clear') }}</el-button>
        <el-button @click="func.search" class="search" type="primary" size="small">{{ $t('g.search') }}</el-button>
      </div>
    </div>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize
          }}</span>
        <div class="header-icons">
        </div>
      </div>
      <el-table :data="formData.tableData" :stripe="true" @row-click="func.showDetails" style="width: 100%"
        id="exportTab">
        <el-table-column type="expand">
        </el-table-column>
        <el-table-column prop="Id" label="#" width="80">
        </el-table-column>
        <el-table-column prop="AgentCode" v-if="!isOperator()" :label="$t('openSlotsLit.agent_code')" width="100">
        </el-table-column>
        <el-table-column prop="SiteName" width="120" :label="$t('openSlotsLit.site_name')">
        </el-table-column>
        <el-table-column prop="DevId" :label="$t('openSlotsLit.dev_id')" width="250">
        </el-table-column>
        <el-table-column prop="UserId" :label="$t('openSlotsLit.user_id')" width="80">
        </el-table-column>
        <el-table-column prop="UserName" :label="$t('bill.nickname')" width="140">
        </el-table-column>
        <el-table-column prop="Mobile" :label="$t('member.mobile')" width="120">
        </el-table-column>
        <el-table-column prop="OpenMsg" :label="$t('order.remark')" width="150">
        </el-table-column>
        <el-table-column prop="CreatedAt" min-width="180" :label="$t('g.create_at')">
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
        :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog :title="$t('g.detail')" v-model="formData.dialogDisplayVisible" width="60%">
      <el-descriptions border column="2">
        <el-descriptions-item label="Id">{{ formData.curData.Id }}</el-descriptions-item>
        <el-descriptions-item :label="$t('openSlotsLit.agent_code')" v-if="!isOperator()">{{ formData.curData.AgentCode
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('openSlotsLit.site_name')">{{ formData.curData.SiteName
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('openSlotsLit.dev_id')">{{ formData.curData.DevId }}</el-descriptions-item>
        <el-descriptions-item :label="$t('openSlotsLit.user_id')">{{ formData.curData.UserId }}</el-descriptions-item>
        <el-descriptions-item :label="$t('bill.nickname')">{{ formData.curData.UserName }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.mobile')">{{ formData.curData.Mobile }}</el-descriptions-item>
        <el-descriptions-item :label="$t('order.remark')">{{ formData.curData.OpenMsg }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.create_at')">{{ formData.curData.CreatedAt }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions border column="1">
        <el-descriptions-item :label="$t('menu.battery_list')">{{
          formData.curData.BatIds }}</el-descriptions-item>
      </el-descriptions>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="default" @click="formData.dialogDisplayVisible = false">{{ $t('g.btn_cancel') }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted, computed } from 'vue'
import { shortcuts, operators ,cascade_options_direct} from '@/data/default'
import axios from "axios"
import { user, isOperator } from '@/data/token'
import i18n from '@/data/i18n/index'
export default {
  name: 'openSlot',
  components: {
  },
  setup() {
    const t = i18n.global.t
    const tableData = []
    const render = {
      operators,
      shortcuts,
      cascade_options_direct
    }
    const formData = reactive({
      tableData,
      slots: {},
      curData: {},
      valTime: '',
      valTypes: -1,
      valResult: -1,
      dialogVisible: false,
      dialogDisplayVisible: false,
      curPage: 1,
      pageSize: 10,
      total: 0,
      valName: '',
      valCode: 0,
      valDevName: '',
      UpdateAtLast: {},//电池更新最后时间
      props:{
          children:"Children",expandTrigger: 'hover',
          label:"Name",
          value:"CodePath",
          emitPath:false,
          checkStrictly:true
      },
    })
    const func = {
      showDetails(row) {
        formData.dialogDisplayVisible = true;
        formData.curData = row;
      },
      search() {
        func.loadData()
      },
      clear() {
        formData.valCode = '';
        formData.valDevName = '';
        formData.valphone = '';
        formData.valSiteName = '';
        formData.valTime = '';
        func.loadData()
      },
      rowDblClick(row, column, e) {
        formData.curData = row
        formData.dialogVisible = true
      },
      pageChange(val) {
        func.loadData(val)
      },
      showAddDataDialog() {
        formData.curData = {
        }
        formData.dialogVisible = true
      },
      async loadData(page = 1) {
        try {
          formData.tableData = []
          formData.curPage = page
          let params = {
            page,
          }
          if (formData.valCode) {
            params.AgentCode = formData.valCode
          }
          if (formData.valDevName) {
            params.DevId = formData.valDevName
          }
          if (formData.valSiteName) {
            params.SiteName = formData.valSiteName
          }
          if (formData.valphone) {
            params.Mobile = formData.valphone
          }

          if (formData.valTime) {
            params.StartAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
            params.EndAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)
          }
          let res = await axios.get(`/admin/openslotlog/get`, {
            params
          })
          formData.total = res.Total
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        } catch (e) {
          console.log("loadData", e)
        }
      }
    }
    onMounted(() => {
      func.loadData()
    })
    return {
      func,
      render,
      formData,
      token: user.token,
      isOperator,
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;

  .table-detail {
    display: flex;
    flex-direction: column;
    margin-right: 3rem;
    padding-left: 2rem;
    // background-color: grey;

    // padding-top: 1rem;

    .slots {
      margin-top: 1rem;

      .slot {
        display: flex;
        margin-bottom: 0.5rem;

        >div {
          width: 8rem;

          &:first-child {
            width: 6rem;
          }
        }


      }
    }

    >.item {
      margin-right: 0.625rem;
      display: flex;
      align-items: center;
      margin-bottom: 0.625rem;
      color: #333333;

      >img {
        margin-left: 0.625rem;
        width: 4rem;
        height: 3rem;
        object-fit: cover;
      }
    }
  }

  .boximg {
    width: 4rem;
    height: 3rem;
    object-fit: cover;
  }

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }

      >.header-icons {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;

        .header-icon {
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color: #969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    .inp-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: left;

      >.item {
        width: 33%;
        display: flex;
        align-items: left;

        margin-bottom: 1.25rem;

        .label {
          margin-right: 0.625rem;
          width: 4.375rem;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }
  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  >.data-table {
    :deep(.el-table) {
      .cell {
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .el-table__row {
        cursor: pointer;
      }
    }
  }

  >.pagination {
    text-align: right;
    margin-top: 1.25rem;
  }

  .el-form-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    :deep(.el-form-item) {
      width: 49%;
    }

    &.line-3 {
      :deep(.el-form-item) {
        min-width: 32%;
      }
    }

    .inp {
      width: 100%;
    }

    .avatar-uploader {
      :deep(.el-upload) {
        border: 1px dashed #d9d9d9;
        border-radius: 0.375rem;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        &:hover {
          border-color: #409eff;
        }
      }
    }

    .avatar-uploader-icon {
      font-size: 1.75rem;
      color: #8c939d;
      width: 11rem;
      height: 9rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .avatar {
      width: 11rem;
      height: 9rem;
      display: block;
      object-fit: cover;
    }
  }

  .el-select-my {
    text-align: left;
    display: block;
  }

  :deep(.m-dialog) {
    max-width: 70rem;
    // width:70vw;
  }
}

@media screen and (max-width:800px) {
  .content {
    >.card-box {
      .inp-list {
        >.item {
          width: 100%;
        }
      }
    }
  }
}
</style>

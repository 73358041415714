<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">{{ $t("g.condition") }}</span>
      </div>
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t('decision.time') }}</span>
          <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" size="small" type="daterange"
            unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
        </div>
        <!-- <div class="item" v-if="!isOperator()">
          <span class="label">{{ $t('g.operator') }}</span>
          <el-select class="inp" size="small" v-model="formData.valOperator" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.operators" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div> -->
        <div class="item">
          <span class="label">{{ $t('member.status') }}</span>
          <el-select class="inp" size="small" v-model="formData.valStatus" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.statuses" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t('order.name') }}</span>
          <el-input class="inp" size="small" v-model="formData.valTel" :placeholder="$t('order.inp.name')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('g.operator') }}</span>
          <el-cascader size="small" style="width: 100%;flex: 1;" :clearable="true" v-model="formData.valOperator"
            :props="formData.props" :options="render.cascade_options_direct" />
        </div>
        <!-- <div class="item">
          <span class="label">关联查下级</span>
          <el-select class="inp" size="small" v-model="formData.IsStartWith" :placeholder="$t('g.choose')">
            <el-option label="否" :value="0">
            </el-option>
            <el-option label="是" :value="1">
            </el-option>
          </el-select>
        </div> -->

        <div class="item">
          <span class="label">{{ $t('order.no') }}</span>
          <el-input class="inp" size="small" v-model="formData.valOrderSn"
            :placeholder="$t('order.inp.order_no')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.no') }}</span>
          <el-input class="inp" size="small" v-model="formData.valDeviceId"
            :placeholder="$t('order.inp.device_no')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.deposit') }}</span>
          <el-input class="inp" size="small" v-model="formData.valDeposit"
            :placeholder="$t('device.deposit')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('member.mobile') }}</span>
          <el-input class="inp" size="small" v-model="formData.valPhone" :placeholder="$t('member.mobile')"></el-input>
        </div>

      </div>
      <div class="btn-ct">
        <el-button @click="func.exportAllExcel(0)" class="search" type="info" size="small">{{
          $t('openSlotsLit.order_export') }}</el-button>
        <el-button @click="func.search" class="search" type="primary" size="small">{{ $t('g.search') }}</el-button>
      </div>
    </div>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize
          }}</span>
        <div class="header-icons">
          <!-- <i @click="func.loadData()" class="el-icon-download header-icon"></i> -->
          <el-icon @click="func.refresh()" class="header-icon">
            <Refresh />
          </el-icon>
        </div>
      </div>
      <el-table :data="formData.tableData" style="width: 100%" @row-click="func.showDetails">
        <el-table-column type="expand">
          <template #default="props">
            <div class="table-expand">
              <div class="devimg">
                <div class="img-item" v-for="(item, idx) in props.row.DevImageList" :key="idx">
                  <div class="label">{{ item.TypeName }}</div>
                  <el-image class="img" :src="item.Image" :preview-src-list="props.row.ImagePre" fit="cover">
                  </el-image>
                </div>
              </div>
              <p v-if="props.row.FailReason">{{ $t('order.failed_reason') }}: {{ props.row.FailReason }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="Id" label="Id" width="100" v-if="!isOperator()">
        </el-table-column>
        <el-table-column prop="OrderSn" :label="$t('order.no')" width="180">
        </el-table-column>
        <el-table-column prop="AgentCode" v-if="!isOperator()" :label="$t('g.agent_code')" width="80">
        </el-table-column>
        <el-table-column prop="NamePath" min-width="180" :label="$t('g.operator_ownership')" :show-overflow-tooltip='true'>
        </el-table-column>
        <el-table-column prop="UserInfo.Nickname" width="80" :label="$t('bill.nickname')">
        </el-table-column>
        <el-table-column prop="UserInfo.Mobile" width="120" :label="$t('member.mobile')">
        </el-table-column>
        <el-table-column prop="UserInfo.IdName" width="80" :label="$t('member.IdName')">
        </el-table-column>
        <el-table-column prop="DeviceId" width="250" :label="$t('device.no')" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="DeviceSiteName" width="120" :label="$t('device.site_name')" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="DepositOrderInfo.BatteryId" min-width="250" :label="$t('member.battery_no')"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="DepositOrderInfo.BatterySoc" width="100" :label="$t('device.battery_soc')">
        </el-table-column>
        <el-table-column prop="DepositOrderInfo.SlotId" width="100" :label="$t('order.slot_get')">
        </el-table-column>
        <el-table-column prop="DepositOrderInfo.Money" width="80" :label="$t('device.deposit')">
        </el-table-column>
        <el-table-column prop="PayWayStr" width="100" :label="$t('order.pay_way')">
        </el-table-column>
        <el-table-column prop="CreatedAtStr" width="200" :label="$t('g.create_at')">
        </el-table-column>
        <el-table-column min-width="80" label="付款状态">
          <template #default="scope">
            <el-tag
              :type="scope.row.StatusStr == $t('member.statuses.success') ? 'success' : (scope.row.StatusStr == '未支付' ? 'danger' : 'warning')"
              effect="dark">
              {{ scope.row.StatusStr }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column min-width="80" label="租电状态">
          <template #default="scope">
            <el-tag :type="scope.row.DeviceStatusStr === '成功' ? 'success' : 'warning'" effect="dark">
              {{ scope.row.DeviceStatusStr }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="150" :label="$t('order.failed_reason')" prop="FailReason">
        </el-table-column>
        <el-table-column prop="Remark" width="120" :label="$t('order.remark')" show-overflow-tooltip>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
        :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog :title="$t('g.detail')" v-model="formData.dialogDisplayVisible" style="width:90%">
      <el-descriptions border column="4">
        <el-descriptions-item :label="$t('order.no')">{{ formData.curData.OrderSn }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.agent_code')" v-if="!isOperator()">{{ formData.curData.AgentCode
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.mobile')">{{ formData.curData.UserInfo?.Mobile
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('bill.nickname')">{{ formData.curData.UserInfo?.Nickname
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.IdName')">{{ formData.curData.UserInfo?.IdName
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.no')">{{ formData.curData.DeviceId }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.site_name')">{{ formData.curData.DeviceSiteName
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.battery_no')">{{ formData.curData.DepositOrderInfo.BatteryId
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.battery_soc')">{{ formData.curData.DepositOrderInfo.BatterySoc
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('order.slot_get')">{{ formData.curData.DepositOrderInfo.SlotId
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.deposit')">{{ formData.curData.DepositOrderInfo.Money
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('order.pay_way')">{{ formData.curData.PayWayStr }}</el-descriptions-item>

        <el-descriptions-item label="付款状态">
          <el-tag
            :type="formData.curData.StatusStr == $t('member.statuses.success') ? 'success' : (formData.curData.StatusStr == '未支付' ? 'danger' : 'warning')"
            effect="dark">
            {{ formData.curData.StatusStr }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="租电状态">
          <el-tag :type="formData.curData.DeviceStatusStr === '成功' ? 'success' : 'warning'" effect="dark">
            {{ formData.curData.DeviceStatusStr }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('order.failed_reason')">{{ formData.curData.FailReason
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('order.remark')">{{ formData.curData.Remark }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.create_at')">{{ formData.curData.CreatedAtStr }}</el-descriptions-item>

      </el-descriptions>
      <div>
        <div style="margin: 10px 0;">事件</div>
        <div v-for="item in formData.curData.eboxEvent">
          {{ item.Type }} {{ item.CreatedAtStr }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { dayjs } from 'element-plus'
import { reactive, onMounted } from 'vue'
import axios from 'axios'
import { operators, updateOperators, shortcuts, cascade_options_direct } from '@/data/default'
import { toDay } from "@/common/func"
import i18n from '@/data/i18n/index'
import { isOperator } from '@/data/token'
import * as XLSX from 'xlsx/xlsx.mjs'
export default {
  name: 'OrderBattery',
  setup() {
    const t = i18n.global.t
    const tableData = []
    const statuses = [
      {
        label: t('member.statuses.all'),
        value: -1
      },
      {
        label: t('member.statuses.success'),
        value: 1
      },
      {
        label: t('member.statuses.failed'),
        value: 0
      },
      {
        label: t('member.statuses.returned'),
        value: 2
      },
      {
        label: '退款中',
        value: 3
      },
      {
        label: '退款失败',
        value: 4
      },
    ]
    const render = {
      shortcuts,
      statuses,
      operators,
      cascade_options_direct
    }
    const formData = reactive({
      tableData,
      curData: {},
      valOperator: 0,
      valStatus: -1,
      valTime: '',
      valTel: '',
      valOrderSn: '',
      dialogDisplayVisible: false,
      valDeviceId: '',
      // pagination
      curPage: 1,
      pageSize: 10,
      total: 1,
      props: {
        children: "Children", expandTrigger: 'hover',
        label: "Name",
        value: "CodePath",
        emitPath: false,
        checkStrictly: true,
      },
      IsStartWith: 1
    })
    const func = {
      async exportAllExcel(type = 1) {
        let params = {
        }
        if (formData.valOrderSn) {
          params.orderSn = formData.valOrderSn
        }
        if (formData.valDeviceId) {
          params.deviceId = formData.valDeviceId
        }
        if (formData.valOperator) {
          params.agentcode = formData.valOperator
        }
        if (formData.valTel) {
          params.nickname = formData.valTel
        }
        if (formData.valPhone) {
          params.phoneNum = formData.valPhone
        }
        if (formData.valDeposit) {
          params.orderPrice = formData.valDeposit
        }
        if (formData.valStatus != -1) {
          params.status = formData.valStatus
        }
        params.isExport = true
        // params.DeviceStatus = formData.valStatus
        if (formData.valTime) {
          params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
          params.endAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)
        }
        let loading = ''
        if (params.exportType == 1) {
          loading = ElLoading.service({
            lock: true,
            text: t('openSlotsLit.loading'),
            background: 'rgba(0, 0, 0, 0.7)',
          })
        }

        let res = await axios.get(`/admin/order/rent`, {
          params
        });
        let excleData = [[]];
        let wscols = [];
        excleData = [[
          'ID',
          t('openSlotsLit.agent_code'),
          t('openSlotsLit.site_name'),
          t('openSlotsLit.dev_id'),
          '姓名',
          '手机',
          '状态',
          '押金',
          '备注',
          '失败原因',
          t('g.create_at'),
        ]];
        wscols = [    // 每列不同宽度px
          { wch: 10 },
          { wch: 10 },
          { wch: 20 },
          { wch: 25 },
          { wch: 10 },
          { wch: 12 },
          { wch: 4 },
          { wch: 6 },
          { wch: 25 },
          { wch: 22 },
          { wch: 22 },
        ];
        const dictType = [
          t('openSlotsLit.types.all_slot'),
          t('openSlotsLit.types.all_empty_slot'),
          t('openSlotsLit.types.all_battery_slot'),
          t('openSlotsLit.types.Specify_slot'),
          t('openSlotsLit.types.full_charge_slot'),
        ]
        if (res && res.List) {
          for (let item of res.List) {
            let arr = [];
            item.StatusStr = item.DeviceStatus === 1 ? (item.Status == 2 ? t('order.returned') : t('member.statuses.success')) : t('member.statuses.failed')
            arr = [
              item.Id,
              item.AgentCode,
              item.DeviceSiteName,
              item.DeviceId,
              item.UserInfo?.IdName,
              item.UserInfo?.Mobile,
              item.StatusStr,
              item.DepositOrderInfo?.Money,
              item.Remark,
              item.FailReason,
              toDay(item.CreatedAt),
            ]
            excleData.push(arr)
          }
        }

        const worksheet = XLSX.utils.aoa_to_sheet(excleData);
        worksheet["!cols"] = wscols;
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, '租电订单' + '.xlsx');
      },
      async getEboxEvent(orderSn) {
        let params = {
          orderSn
        }
        let res = await axios.get(`/admin/order/event`, {
          params
        })
        if (res && res.List) {
          res.List.map((item) => {
            item.CreatedAtStr = dayjs.unix(item.CreateAt).format('YYYY-MM-DD HH:mm:ss')
          })
          formData.curData.eboxEvent = res.List
        }
      },
      showDetails(row) {
        formData.dialogDisplayVisible = true;
        formData.curData = row;
        func.getEboxEvent(formData.curData.OrderSn)
      },
      search() {
        console.log('formData', formData)
        func.loadData(1)
      },
      pageChange(val) {
        console.log('page change', val)
        func.loadData(val)
      },
      refresh() {
        formData.valOperator = 0
        formData.valStatus = -1
        formData.valTime = ''
        formData.valTel = ''
        func.loadData(1)
      },
      async loadData(page = 1) {
        try {
          formData.tableData = []
          formData.curPage = page
          let params = {
            page,
          }
          if (formData.valOrderSn) {
            params.orderSn = formData.valOrderSn
          }
          if (formData.valDeviceId) {
            params.deviceId = formData.valDeviceId
          }
          if (formData.valOperator) {
            params.agentcode = formData.valOperator
          }
          if (formData.valTel) {
            params.nickname = formData.valTel
          }
          if (formData.valPhone) {
            params.phoneNum = formData.valPhone
          }
          if (formData.valDeposit) {
            params.orderPrice = formData.valDeposit
          }
          if (formData.valStatus != -1) {
            params.status = formData.valStatus
          }
          // params.DeviceStatus = formData.valStatus
          if (formData.valTime) {
            params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
            params.endAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)
          }
          params.IsStartWith = formData.IsStartWith
          console.log(JSON.stringify(params));

          let res = await axios.get(`/admin/order/rent`, {
            params
          })
          formData.total = res.Total
          res.List.map((item) => {
            item.CreatedAtStr = toDay(item.CreatedAt)
            // item.StatusStr = item.DeviceStatus === 1 ? (item.Status == 2 ? t('order.returned') : t('member.statuses.success')) : t('member.statuses.failed')
            item.PayWayStr = ""
            switch (item.Status) {
              case 0:
                item.StatusStr = '未支付'
                break;
              case 1:
                item.StatusStr = t('member.statuses.success')
                break;
              case 2:
                item.StatusStr = '已退款'
                break;
              case 3:
                item.StatusStr = '退款中'
                break;
              case 4:
                item.StatusStr = '退款失败'
                break;
              default:
                item.StatusStr = t('member.statuses.failed')
            }
            switch (item.DeviceStatus) {
              case 0:
                item.DeviceStatusStr = '失败'
                break;
              case 1:
                item.DeviceStatusStr = '成功'
                // if (item.Status === 2) {
                //   item.DeviceStatusStr = '已退还'
                // } else {
                //   item.DeviceStatusStr = '成功'
                // }
                break;
            }

            item.ImagePre = []
            if (!item.DevImageList) {
              item.DevImageList = []
            }
            item.DevImageList.map((item2) => {
              item2.TypeName = ""
              switch (item2.OperType) {
                case 0:
                  item2.TypeName = t('order.imgs.t0')
                  break
                case 1:
                  item2.TypeName = t('order.imgs.t1')
                  break
                case 2:
                  item2.TypeName = t('order.imgs.t2')
                  break
                case 3:
                  item2.TypeName = t('order.imgs.t3')
                  break
              }
              item.ImagePre.push(item2.Image)
            })
            if (item.PayWay == 1) {
              item.PayWayStr = t('order.pay_ways.balance')
            } else if (item.PayWay == 2) {
              item.PayWayStr = t('order.pay_ways.wechat')
            } else if (item.PayWay == 5) {
              item.PayWayStr = t('order.pay_ways.alipay')
            }

          })
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        } catch (e) {
          console.log("loadData", e)
        }
      }
    }
    onMounted(() => {
      console.log("onMounted")
      //updateOperators()
      func.loadData()
    })
    return {
      func,
      render,
      formData,
      isOperator,
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;

  .inp-block {
    display: block;
  }

  .table-expand {
    padding-left: 4rem;
    padding-top: 1rem;
  }

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }

      >.header-icons {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;

        .header-icon {
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color: #969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    .inp-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: left;

      >.item {
        width: 33%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;

        .label {
          margin-right: 0.625rem;
          width: 4.375rem;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }
  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  >.data-table {
    :deep(.el-table) {
      .cell {
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .el-table__row {
        cursor: pointer;
      }
    }
  }

  >.pagination {
    text-align: right;
    margin-top: 1.25rem;
  }

  .el-form-row {
    display: flex;
    justify-content: space-between;

    :deep(.el-form-item) {
      width: 49%;
    }
  }

  .el-select-my {
    text-align: left;
    display: block;
  }

  :deep(.m-dialog) {
    max-width: 70rem;
    // margin-top: 0;
  }
}
</style>

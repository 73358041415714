<template>
  <div class="content">
    <div class="card-box">
      <div class="btn-ct">
        <el-button type="primary" @click="func.newActivity" class="new-btn" size="small">新增公告</el-button>
      </div>
    </div>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{
          $t("g.per_page")
        }}:{{ formData.pageSize }}</span>
        <div class="header-icons"></div>
      </div>
      <el-table :data="formData.tableData" :stripe="true" style="width: 100%">
        <el-table-column prop="Id" label="#" :width="100"> </el-table-column>
        <el-table-column prop="Name" label="标题" :width="150"></el-table-column>
        <el-table-column prop="AgentCode" label="运营商" :width="100" v-if="!isOperator()"></el-table-column>
        <el-table-column prop="EnableMode" label="状态" :width="80">
          <template #default="scope">
            <el-tag :type="scope.row.EnableMode ? 'success' : 'warning'">{{
              render.statuses[scope.row.EnableMode].label
            }}</el-tag></template>
        </el-table-column>
        <el-table-column prop="HomeLayout" label="首页弹窗位置">
          <template #default="scope">
            <!-- <el-tag :type="scope.row.EnableMode ? 'success' : 'warning'">{{
              render.statuses[scope.row.EnableMode].label
            }}</el-tag> -->
            <div v-if="scope.row.HomeLayout == 1">屏幕上方</div>
            <div v-else-if="scope.row.HomeLayout == 2">屏幕中间</div>
            <div v-else-if="scope.row.HomeLayout == 3">不显示弹窗</div>
            <div v-else></div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="PopTimes" label="弹窗次数"></el-table-column> -->
        <el-table-column prop="CreatedAtStr" label="开始时间"></el-table-column>
        <el-table-column prop="UpdatedAtStr" label="结束时间"></el-table-column>
        <el-table-column :label="$t('g.operation')" fixed="right">
          <template #default="scope">
            <el-tag size="large" type="danger" @click="func.del(scope.row.Id)">删除</el-tag>
            <el-tag size="large" type="success" style="margin-left: 1rem"
              @click="func.editDialog(scope.row)">修改</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
        :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog :title="formData.operateType == 1 ? '新增' : '编辑'" v-model="formData.addElDailog" width="85%" center>
      <div class="dailog-content">
        <div class="activity-content">
          <el-form label-width="180px" :rules="rules" :model="formData">
            <el-row>
              <el-col :span="8">
                <el-form-item label="公告标题" prop="name">
                  <el-input v-model="formData.Name" />
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="公告时间" prop="startTime">
                  <el-date-picker class="inp" v-model="formData.valTime" type="datetimerange" unlink-panels
                    :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
                    :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('g.operator')" prop="name">
                  <!-- size="small" -->
                  <!-- <el-select class="inp" v-model="formData.AgentCode" :placeholder="$t('g.choose')"
                    style="width: 100%;">
                    <el-option v-for="item in render.operatorList" :key="item.value" :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select> -->
                  <el-cascader  size="small" style="width: 100%;flex: 1;" :clearable="true" :placeholder="$t('g.choose')" v-model="formData.AgentCode"
                  :props="formData.props" :options="render.cascade_options_direct" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('member.status')">
                  <el-select class="inp" v-model="formData.EnableMode" :placeholder="$t('g.choose')"
                    style="width: 100%;">
                    <el-option v-for="item in render.statuses" :key="item.value" :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="是否跳转">
                  <el-select class="inp" v-model="formData.jumpType" :placeholder="$t('g.choose')" style="width: 100%;">
                    <el-option v-for="item in render.jump" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="首页弹窗位置">
                  <el-select class="inp" v-model="formData.HomeLayout" :placeholder="$t('g.choose')"
                    style="width: 100%;" @change="func.HomeSelect">
                    <el-option v-for="item in render.index_popup" :key="item.value" :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-show="formData.HomeLayout != 1">
                <el-form-item label="弹框次数">
                  <el-input v-model="formData.Poptimes" type="number" :min='1' @input="func.PoptimesInput" />
                </el-form-item>
              </el-col>
              <el-col :span="8" v-show="formData.HomeLayout != 1">
                <el-form-item label="允许不在提醒">
                  <el-select class="inp" v-model="formData.RemindSwitch" :placeholder="$t('g.choose')"
                    :disabled="formData.disabled_remind" style="width: 100%;">
                    <el-option v-for="item in render.popup" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>


              <!-- <el-col :span="8">
                <el-form-item label="换电页面弹窗位置">
                  <el-select class="inp" v-model="formData.ExLayout" :placeholder="$t('g.choose')">
                    <el-option v-for="item in render.ex_popup" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col> -->
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="公告内容" width="350px">
                  <el-input v-model="formData.Detail" :rows="7" type="textarea" placeholder="请输入公告内容" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="formData.addElDailog = false">取消</el-button>
          <el-button type="primary" @click="func.add"> 确认 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, onMounted, computed } from "vue";
import activityStyle from "@/components/activityMode/activityStyle.vue";
import { toDay } from "@/common/func";
import i18n from "@/data/i18n/index";
import { user, isOperator, isAdmin } from "@/data/token";
import { shortcuts, operatorList ,cascade_options_direct} from "@/data/default";
import Recharge from "../order/recharge.vue";
import axios from "axios";
import { ElMessage, ElMessageBox, ElLoading, dayjs } from "element-plus";
import { provinceAndCityData } from "element-china-area-data";
export default {
  name: "activityManage",
  components: {
    activityStyle,
  },
  setup() {
    const t = i18n.global.t;
    const couponList = [];
    const render = {
      shortcuts,
      couponList,
      statuses: [
        {
          label: t("device.disable"),
          value: 0,
        },
        {
          label: t("device.enable"),
          value: 1,
        },
      ],
      jump: [
        {
          label: "否",
          value: 0,
        },
        {
          label: "跳转至拉新",
          value: 1,
        },
        {
          label: "跳转至优惠券",
          value: 2,
        },
      ],
      popup: [
        {
          label: "是",
          value: 1,
        },
        {
          label: "否",
          value: 0,
        },
      ],
      index_popup: [
        {
          label: "屏幕上方",
          value: 1,
        },
        {
          label: "屏幕中间",
          value: 2,
        },
      ],
      ex_popup: [
        {
          label: "屏幕上方",
          value: 1,
        },
        {
          label: "屏幕中间",
          value: 2,
        },
        {
          label: "不显示弹窗",
          value: 3,
        },
      ],
      awardUnit: [
        {
          label: "分钟",
          value: 0,
        },
        {
          label: "天",
          value: 0,
        },
        {
          label: "元",
          value: 0,
        },
      ],
      operatorList,
      cascade_options_direct
    };
    const rules = reactive({});
    const formData = reactive({
      tableData: [],
      curPage: 1,
      pageSize: 10,
      total: 0,
      jumpType: 0,
      Poptimes: 1,
      RemindSwitch: 0,
      AgentCode: '',
      disabled_remind: true,
      HomeLayout: 2,
      ExLayout: 1,
      props: {
        children: "Children",expandTrigger: 'hover',
        label: "Name",
        value: "Code",
        emitPath: false,
        checkStrictly: true,
      },
    })
    const func = {
      newActivity() {
        formData.operateType = 1
        formData.EnableMode = 1
        formData.Name = ''
        formData.AgentCode = ''
        formData.valTime = []
        formData.Detail = ''
        formData.addElDailog = true;
      },
      async search() {
        func.loadData()
      },
      pageChange(val) {
        console.log('page change', val)
        func.loadData(val)
      },
      HomeSelect(val) {
      },
      editDialog(row) {
        formData.operateType = 2
        if (row) {
          formData.Id = row.Id
          formData.Name = row.Name
          formData.EnableMode = row.EnableMode
          formData.AgentCode = row.AgentCode
          formData.Detail = row.Detail
          formData.jumpType = row.JmpAddress
          formData.Poptimes = row.PopTimes
          formData.RemindSwitch = row.RemindSwitch
          formData.HomeLayout = row.HomeLayout
          formData.ExLayout = row.ExLayout
          formData.valTime = []
          if (formData.Poptimes == 1) {
            formData.disabled_remind = true
          } else {
            formData.disabled_remind = false
          }
          if (row.StartTime) {
            formData.valTime.push(dayjs.unix(row.StartTime).format())
          }
          if (row.EndTime) {
            formData.valTime.push(dayjs.unix(row.EndTime).format())
          }
        }
        formData.addElDailog = true;
      },
      async del(Id) {
        ElMessageBox.confirm(t('g.sure_delete'), {
          confirmButtonText: t('confirm'),
          cancelButtonText: t('cancel'),
        }).then(() => {
          try {
            axios.post('/admin/notice/delete', {
              Id
            }).then((res) => {
              if (res == '操作成功') {
                ElMessage({
                  type: 'success',
                  message: t('g.success')
                })
              } else {
                ElMessage({
                  type: 'error',
                  message: t("err_code.20005")
                })
              }
              func.loadData()
            })

          } catch (error) {
            ElMessage({
              type: 'error',
              message: t("err_code.20005")
            })
          }

        })
      },
      PoptimesInput(val) {
        if (val == 1) {
          formData.disabled_remind = true
        } else {
          formData.disabled_remind = false
        }
      },
      async add(id) {
        let params = {
          EnableMode: formData.EnableMode
        }
        if (formData.Name) {
          params.Name = formData.Name
        }
        if (formData.AgentCode) {
          params.AgentCode = formData.AgentCode
        }
        if (formData.Poptimes) {
          params.PopTimes = formData.Poptimes
        }
        params.JmpAddress = formData.jumpType
        params.HomeLayout = formData.HomeLayout
        params.ExLayout = formData.ExLayout
        if (formData.valTime) {
          params.StartTime = Math.floor(new Date(formData.valTime[0]).getTime() / 1000)
          params.EndTime = Math.floor(new Date(formData.valTime[1]).getTime() / 1000)
        }
        params.RemindSwitch = formData.RemindSwitch
        if (formData.Detail) {
          params.Detail = formData.Detail
        }
        let res
        if (formData.operateType == 2) {
          params.Id = formData.Id
          //return
          res = await axios.post('/admin/notice/edit', params);
        } else {
          res = await axios.post('/admin/notice/add', params);
        }

        if (res) {
          formData.addElDailog = false;
          func.loadData()
          ElMessage({
            type: 'success',
            message: t('g.success')
          })
        } else {
          ElMessage({
            type: 'error',
            message: t('err_code.20017')
          })
        }

      },
      async loadData(page = 1) {
        let params = {
          page,
        };
        formData.curPage = page;
        // if (formData.id) {
        //   params.id = formData.id
        // }
        let res = await axios.get("/admin/notice/get", {
          params,
        });
        console.log(res, "列表参数");
        if (res) {
          formData.total = res.Total;
          res.List.map((item) => {
            item.CreatedAtStr = toDay(item.StartTime);
            item.UpdatedAtStr = toDay(item.EndTime);
          });
          formData.pageSize = res.PageSize;
          formData.tableData = res.List;
        }
      },
    };
    onMounted(() => {
      if (provinceAndCityData[0].value != 0) {
        provinceAndCityData.unshift({
          value: "0",
          label: "全部",
        });
      }
      //func.getCoupon()
      func.loadData();
      // func.loadData()
    });
    return {
      formData,
      func,
      formData,
      render,
      token: user.token,
      isOperator,
      isAdmin,
      rules,
      provinceAndCityData,
    };
  },
};
</script>
<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;
    }

    .inp-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;

      >.item {
        width: 49%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;

        .label {
          margin-right: 0.625rem;
          width: 4.375rem;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    >.pagination {
      text-align: right;
      margin-top: 1.25rem;
    }
  }

  .new-el-dialog {
    .dailog-content {
      display: flex;
    }

    .activity-content {
      flex: 2;
    }

    // .activity-style {
    //   flex: 1;
    // }
  }

  :deep(.m-dialog) {
    // max-width: 80rem;
    margin-top: 0;
  }
}
</style>
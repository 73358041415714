<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">{{ $t("g.condition") }}</span>
      </div>
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t('decision.time') }}</span>
          <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" size="small" type="daterange"
            unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
        </div>
        <!-- <div class="item" v-if="!isOperator()">
          <span class="label">{{ $t('g.operator') }}</span>
          <el-select class="inp" size="small" v-model="formData.valOperator" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.operators" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div> -->
        <div class="item">
          <span class="label">{{ $t('member.status') }}</span>
          <el-select class="inp" size="small" v-model="formData.valStatus" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.statuses" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item" >
          <span class="label">{{ $t('g.operator') }}</span>
          <el-cascader size="small"  style="width: 100%;flex: 1;" :clearable="true" v-model="formData.valOperator" :props="formData.props" :options="render.cascade_options_direct" />
        </div>
        <!-- <div class="item">
          <span class="label">关联查下级</span>
          <el-select class="inp" size="small" v-model="formData.IsStartWith" :placeholder="$t('g.choose')">
            <el-option label="否" :value="0">
            </el-option>
            <el-option label="是" :value="1">
            </el-option>
          </el-select>
        </div> -->
        <div class="item">
          <span class="label">{{ $t('order.name') }}</span>
          <el-input class="inp" size="small" v-model="formData.valTel" :placeholder="$t('order.inp.name')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('order.no') }}</span>
          <el-input class="inp" size="small" v-model="formData.valOrderSn"
            :placeholder="$t('order.inp.order_no')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.no') }}</span>
          <el-input class="inp" size="small" v-model="formData.valDeviceId"
            :placeholder="$t('order.inp.device_no')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('order.site_name') }}</span>
          <el-input class="inp" size="small" v-model="formData.valSiteName"
            :placeholder="$t('order.site_name')"></el-input>
        </div>
        <!-- <div class="item">
          <span class="label">{{$t('member.mobile')}}</span>
          <el-input class="inp" size="small" v-model="formData.valPhone" :placeholder="$t('member.mobile')"></el-input>
        </div> -->
      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary" size="small">{{ $t('g.search') }}</el-button>
      </div>
    </div>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize
          }}</span>
        <div class="header-icons">
          <!-- <i @click="func.loadData()" class="el-icon-download header-icon"></i> -->
          <el-icon @click="func.refresh()" class="header-icon">
            <Refresh />
          </el-icon>
        </div>
      </div>
      <el-table :data="formData.tableData" @row-click="func.showDetails" style="width: 100%"
        :summary-method="func.getSummaries" show-summary>
        <el-table-column type="expand">
          <template #default="props">
            <div class="table-expand">
              <p>{{ $t('order.times.charge_start') }}: {{ props.row.ChargeBatteryOrderInfo.StartTimeStr }}</p>
              <p>{{ $t('order.times.charge_end') }}: {{ props.row.ChargeBatteryOrderInfo.EndTimeStr }}</p>
              <p>{{ $t('order.times.took_battery') }}: {{ props.row.ChargeBatteryOrderInfo.TakeBatteryTimeStr }}</p>
              <p v-if="props.row.ChargeBatteryOrderInfo.OriginPowerLevel">{{ $t('order.power_level') }}: {{
                props.row.ChargeBatteryOrderInfo.OriginPowerLevel }}W</p>
              <p v-if="props.row.DeviceSiteName">{{ $t('order.site_name') }}: {{ props.row.DeviceSiteName }}</p>
              <p v-if="props.row.EndReason">{{ $t('order.end_reason') }}: {{ props.row.EndReason }}</p>
              <p v-if="props.row.FailReason">{{ $t('order.failed_reason') }}: {{ props.row.FailReason }}</p>
              <p v-if="props.row.ChargeBatteryOrderInfo.Remark">{{ $t('order.remark') }}: {{
                props.row.ChargeBatteryOrderInfo.Remark }}</p>
              <div class="devimg">
                <div class="img-item" v-for="(item, idx) in props.row.DevImageList" :key="idx">
                  <div class="label">{{ item.TypeName }}</div>
                  <el-image class="img" :src="item.Image" :preview-src-list="props.row.ImagePre" fit="cover">
                  </el-image>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="OrderSn" :label="$t('order.no')" width="200">
        </el-table-column>
        <el-table-column prop="AgentCode" v-if="!isOperator()" :label="$t('g.agent_code')" width="100">
        </el-table-column>
        <el-table-column prop="NamePath" min-width="180" :label="$t('g.operator_ownership')" :show-overflow-tooltip='true'>
        </el-table-column>
        <el-table-column prop="UserInfo.Nickname" width="120" :label="$t('bill.nickname')">
        </el-table-column>
        <!-- <el-table-column prop="UserInfo.Mobile" width="120" :label="$t('member.mobile')">
        </el-table-column> -->
        <el-table-column prop="DeviceId" min-width="260" :label="$t('device.no')">
        </el-table-column>
        <el-table-column prop="DeviceSiteName" min-width="120" :label="$t('device.site_name')"></el-table-column>
        <el-table-column width="140" :label="$t('member.status')">
          <template #default="scope">
            <el-tag :type="scope.row.StatusClass" @click="func.tapStatus(scope.row)" effect="dark">
              {{ scope.row.StatusStr }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="ChargeBatteryOrderInfo.ChargeMoney" width="160" :label="$t('order.moneys.charge')">
        </el-table-column>
        <el-table-column prop="ChargeBatteryOrderInfo.SaveMoney" width="120" :label="$t('order.moneys.deposit')">
        </el-table-column>
        <el-table-column prop="ChargeBatteryOrderInfo.SaveTime" width="120" :label="$t('order.deposit_duration')">
        </el-table-column>
        <el-table-column prop="ChargeBatteryOrderInfo.ChargeDuration" width="120" :label="$t('order.charge_duration')">
        </el-table-column>
        <el-table-column prop="ChargeBatteryOrderInfo.SlotId" width="100" :label="$t('order.slot_id')">
        </el-table-column>
        <el-table-column prop="OrderPrice" width="100" :label="$t('order.price')">
        </el-table-column>
        <el-table-column prop="CreatedAtStr" min-width="200" :label="$t('g.create_at')">
        </el-table-column>
        <el-table-column width="140" :label="$t('g.operation')">
          <template #default="scope">
            <el-tag v-if="scope.row.ChargeBatteryOrderInfo.ChargeStatus != 3" type="danger"
              @click="func.tapStatus(scope.row)" effect="dark">
              {{ $t('order.end') }}
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
        :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog :title="$t('g.detail')" v-model="formData.dialogDisplayVisible">
      <el-descriptions border column="2">
        <el-descriptions-item :label="$t('order.no')">{{ formData.curData.OrderSn }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.agent_code')" v-if="!isOperator()">{{ formData.curData.AgentCode
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('bill.nickname')">{{ formData.curData.UserInfo.Nickname
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.no')">{{ formData.curData.DeviceId }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.site_name')">{{ formData.curData.DeviceSiteName
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.status')">
          <el-tag :type="formData.curData.StatusClass" @click="func.tapStatus(formData.curData)" effect="dark">
            {{ formData.curData.StatusStr }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('order.moneys.charge')">{{ formData.curData.ChargeBatteryOrderInfo.ChargeMoney
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('order.moneys.deposit')">{{ formData.curData.ChargeBatteryOrderInfo.SaveMoney
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('order.deposit_duration')">{{ formData.curData.ChargeBatteryOrderInfo.SaveTime
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('order.charge_duration')">{{
          formData.curData.ChargeBatteryOrderInfo.ChargeDuration
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('order.slot_id')">{{ formData.curData.ChargeBatteryOrderInfo.SlotId
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('order.price')">{{ formData.curData.OrderPrice }}</el-descriptions-item>

        <el-descriptions-item :label="$t('order.times.charge_start')">{{
          formData.curData.ChargeBatteryOrderInfo.StartTimeStr }}</el-descriptions-item>
        <el-descriptions-item :label="$t('order.times.charge_end')">{{
          formData.curData.ChargeBatteryOrderInfo.EndTimeStr
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('order.times.took_battery')">{{
          formData.curData.ChargeBatteryOrderInfo.TakeBatteryTimeStr }}</el-descriptions-item>
        <el-descriptions-item :label="$t('order.power_level')"
          v-if="formData.curData.ChargeBatteryOrderInfo.OriginPowerLevel">{{
            formData.curData.ChargeBatteryOrderInfo.OriginPowerLevel }}W</el-descriptions-item>
        <el-descriptions-item :label="$t('order.site_name')" v-if="formData.curData.DeviceSiteName">{{
          formData.curData.DeviceSiteName }}</el-descriptions-item>
        <el-descriptions-item :label="$t('order.end_reason')" v-if="formData.curData.EndReason">{{
          formData.curData.EndReason }}</el-descriptions-item>
        <el-descriptions-item :label="$t('order.failed_reason')" v-if="formData.curData.FailReason">{{
          formData.curData.FailReason }}</el-descriptions-item>
        <el-descriptions-item :label="$t('order.remark')" v-if="formData.curData.ChargeBatteryOrderInfo.Remark">{{
          formData.curData.ChargeBatteryOrderInfo.Remark }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.create_at')">{{ formData.curData.CreatedAtStr }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted } from 'vue'
import axios from 'axios'
import { operators, updateOperators, shortcuts,cascade_options_direct} from '@/data/default'
import { toDay } from "@/common/func"
import { ElMessageBox, ElMessage } from 'element-plus'
import i18n from '@/data/i18n/index'
import { isOperator } from '@/data/token'

export default {
  name: 'OrderCbox',
  setup() {
    const t = i18n.global.t
    const tableData = []
    const statuses = [
      {
        label: t('order.cs.all'),
        value: -1
      },
      {
        label: t('order.cs.waiting'),
        value: 0
      },
      {
        label: t('order.cs.charging'),
        value: 1
      },
      {
        label: t('order.cs.full'),
        value: 2
      },
      {
        label: t('order.cs.finished'),
        value: 3
      },
      {
        label: t('order.cs.depositing'),
        value: 4
      },
      {
        label: t('order.cs.prepare_failed'),
        value: 5
      },
      {
        label: t('order.cs.depositing_free'),
        value: 6
      },
      {
        label: t('order.cs.failed'),
        value: 99
      },
    ]
    const render = {
      shortcuts,
      statuses,
      operators,
      cascade_options_direct
    }
    const formData = reactive({
      tableData,
      curData: {},
      valOperator: 0,
      valStatus: -1,
      valTime: '',
      valTel: '',
      valOrderSn: '',
      valDeviceId: '',
      dialogDisplayVisible: false,
      valSiteName: '',
      // pagination
      curPage: 1,
      pageSize: 10,
      total: 1,
      props:{
          children:"Children",expandTrigger: 'hover',
          label:"Name",
          value:"CodePath",
          emitPath:false,
          checkStrictly:true
      },
      IsStartWith:1
    })
    const func = {
      showDetails(row) {
        formData.dialogDisplayVisible = true;
        formData.curData = row;
      },
      getSummaries(options) {
        // return
        const { columns, data } = options;
        const sums = [];
        columns.forEach((column, index) => {

          if (index === 1) {
            sums[index] = t("g.total")
            return;
            //index === 22 || index === 24 || index === 28  这是表格第几列需要合计值
            // 例如只需要第3列的值求和，index===3
          } else if (index === 8 || index === 9) {
            let tmp = column.property.split('.');
            const values = data.map((item) => Number(item[tmp[0]][tmp[1]]));
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              // console.log(JSON.stringify(value),23980);
              if (!isNaN(value)) {
                return Math.floor((prev + curr) * 100) / 100;
              } else {
                return prev;
              }
            }, 0) + t('card.currency');
          } else if (index === 10 || index === 11) {
            let tmp = column.property.split('.');
            const values = data.map((item) => Number(item[tmp[0]][tmp[1]]));
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              // console.log(JSON.stringify(value),23980);
              if (!isNaN(value)) {
                return Math.floor((prev + curr) * 100) / 100;
              } else {
                return prev;
              }
            }, 0) + t('g.minute');
          }
        });
        return sums;
      },
      tapStatus(data) {
        const flag = data.ChargeBatteryOrderInfo.ChargeStatus != 3
        if (!flag) {
          return
        }
        ElMessageBox.prompt(t('order.input_end_reason'), t('tips'), {
          confirmButtonText: t('confirm'),
          cancelButtonText: t('cancel'),
        })
          .then(({ value }) => {
            return axios.post("/admin/order/endBoxCharge", {
              orderId: data.Id,
              remark: value
            })
          })
          .then(() => {
            ElMessage({
              type: 'success',
              message: t('g.success')
            })
            return func.loadData()
          })
          .catch(() => {

          })
      },
      search() {
        console.log('formData', formData)
        func.loadData(1)
      },
      pageChange(val) {
        console.log('page change', val)
        func.loadData(val)
      },
      refresh() {
        formData.valOperator = 0
        formData.valStatus = 2
        formData.valTime = ''
        formData.valTel = ''
        func.loadData(1)
      },
      async loadData(page = 1) {
        try {
          formData.tableData = []
          formData.curPage = page
          let params = {
            page,
          }
          if (formData.valOrderSn) {
            params.orderSn = formData.valOrderSn
          }
          if (formData.valDeviceId) {
            params.deviceId = formData.valDeviceId
          }
          if (formData.valSiteName) {
            params.siteName = formData.valSiteName
          }
          if (formData.valOperator) {
            params.agentcode = formData.valOperator
          }
          if (formData.valPhone) {
            params.phoneNum = formData.valPhone
          }
          if (formData.valTel) {
            params.nickname = formData.valTel
          }
          if (formData.valStatus != -1) {
            params.status = formData.valStatus
          }
          if (formData.valTime) {
            params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
            params.endAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)
          }
          params.IsStartWith = formData.IsStartWith
          let res = await axios.get(`/admin/order/boxCharge`, {
            params
          })
          formData.total = res.Total
          res.List.map((item) => {
            item.CreatedAtStr = toDay(item.CreatedAt)
            item.ChargeBatteryOrderInfo.TakeBatteryTimeStr = item.ChargeBatteryOrderInfo.TakeBatteryTime ? toDay(item.ChargeBatteryOrderInfo.TakeBatteryTime) : "-"

            item.ChargeBatteryOrderInfo.StartTimeStr = item.ChargeBatteryOrderInfo.StartTime ? toDay(item.ChargeBatteryOrderInfo.StartTime) : "-"
            item.ChargeBatteryOrderInfo.EndTimeStr = item.ChargeBatteryOrderInfo.EndTime ? toDay(item.ChargeBatteryOrderInfo.EndTime) : "-"
            if (item.DeviceStatus === 0) {
              item.StatusStr = t('member.statuses.failed')
              item.StatusClass = "danger"
            } else if (item.ChargeBatteryOrderInfo.ChargeStatus === 0) {
              item.StatusStr = t('order.cs.waiting')
              item.StatusClass = "danger"
            } else if (item.ChargeBatteryOrderInfo.ChargeStatus === 1) {
              item.StatusStr = t('order.cs.charging')
              item.StatusClass = ""
            } else if (item.ChargeBatteryOrderInfo.ChargeStatus === 2) {
              item.StatusStr = t('order.cs.full')
              item.StatusClass = "warning"
            } else if (item.ChargeBatteryOrderInfo.ChargeStatus === 3) {
              item.StatusStr = t('order.cs.finished')
              item.StatusClass = "success"
            } else if (item.ChargeBatteryOrderInfo.ChargeStatus === 4) {
              item.StatusStr = t('order.cs.depositing')
              item.StatusClass = ""
            } else if (item.ChargeBatteryOrderInfo.ChargeStatus === 5) {
              item.StatusStr = t('order.cs.failed')
              item.StatusClass = "danger"
            } else if (item.ChargeBatteryOrderInfo.ChargeStatus === 6) {
              item.StatusStr = t('order.cs.depositing_free')
              item.StatusClass = ""
            } else {
              item.StatusStr = t('order.cs.finished')
              item.StatusClass = "success"
            }
            item.ImagePre = []
            if (!item.DevImageList) {
              item.DevImageList = []
            }
            item.DevImageList.map((item2) => {
              item2.TypeName = ""
              switch (item2.OperType) {
                case 0:
                  item2.TypeName = t('order.imgs.t0')
                  break
                case 1:
                  item2.TypeName = t('order.imgs.t1')
                  break
                case 2:
                  item2.TypeName = t('order.imgs.t2')
                  break
                case 3:
                  item2.TypeName = t('order.imgs.t3')
                  break
              }
              item.ImagePre.push(item2.Image)
            })
          })
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        } catch (e) {
          console.log("loadData", e)
        }
      }
    }
    onMounted(() => {
      console.log("onMounted")
      //updateOperators()
      func.loadData()
    })
    return {
      func,
      render,
      formData,
      isOperator,
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;

  .inp-block {
    display: block;
  }

  .table-expand {
    padding-left: 4rem;
    padding-top: 1rem;
  }

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }

      >.header-icons {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;

        .header-icon {
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color: #969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    .inp-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;

      >.item {
        width: 49%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;

        .label {
          margin-right: 0.625rem;
          width: 4.375rem;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }
  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  >.data-table {
    :deep(.el-table) {
      .cell {
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .el-table__row {
        cursor: pointer;
      }
    }
  }

  >.pagination {
    text-align: right;
    margin-top: 1.25rem;
  }

  .el-form-row {
    display: flex;
    justify-content: space-between;

    :deep(.el-form-item) {
      width: 49%;
    }
  }

  .el-select-my {
    text-align: left;
    display: block;
  }
}
</style>

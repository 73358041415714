<template>
  <div class="login_bg" id="loginbg">
    <!-- <div class="login_bg_new"><img :src="require('@/assets/img/login_bg_new.png')" /></div> -->
    <div class="login_bg3">
      <div class="login_bg2">
        <div class="login_hg_left">
          <!-- <img :src="require('@/assets/img/login_bg.png')" /> -->
        </div>
        <div class="login_hg_right">
          <div class="company_logo">
            <img src="@/assets/img/MDA_LOGO.png" alt="麦迪瑞图标">
          </div>
          <div v-if="loginType === 'qrcode'" class="login_black_hg">
            <img class="qrcode" src="../assets/logo.png" />
            <span class="qrcode-txt">{{ $t("login.wechat_scan_to_login") }}</span>
          </div>
          <div class="login_black_hg" v-else>

            <div class="head_log">
              {{ $t("appname") }}
              <p>MANAGE SYSTEM</p>
            </div>
            <div class="unline" style="margin-bottom: 30px"></div>
            <div class="form-group">
              <el-input style="width:260px" size="small" v-model="username"
                :placeholder="$t('rights.username')"></el-input>
            </div>
            <div class="form-group">
              <el-input style="width:260px" type="password" size="small" v-model="password" class="login_input"
                :placeholder="$t('rights.password')"></el-input>
            </div>
            <div class="form-group verification">
              <!-- 40px -->
              <el-input style="width:120px" size="big" v-model="verif.code" class="login_input"
                :placeholder="$t('login.verification_code')"></el-input><el-image style="width:120px;height: auto;"
                :alt="$t('login.verification_code')" :src="verif.img" @click="verifCode"></el-image>
            </div>
            <el-button size="small" class="login_btn" @click="login" type="primary">{{ $t("login.btn_login")
              }}</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, reactive } from 'vue'
import cfg from '@/common/conf'
import { navTo } from '@/common/func'
import { useRoute } from 'vue-router'
import axios from "axios"
import { setUser } from "@/data/token"
import { updateOperators, getCoord } from '@/data/default'
export default {
  name: 'Login',
  setup() {
    const route = useRoute()
    const username = ref('')
    const password = ref('')
    const verif = reactive({
      img: '',
      code: '',
      value: '',
    })
    const loginType = ref('')
    if (route.query.type) {
      loginType.value = route.query.type
    }
    console.log(loginType.value)
    const verifCode = async () => {
      let res = await axios.get("admin/captcha/generate")
      console.log(res);
      verif.img = res.image
      verif.value = res.id

    }
    const login = async () => {
      try {
        let coord = await getCoord()
        let res = await axios.post("/admin/login/doLogin", {
          username: username.value,
          password: password.value,
          verifyValue: verif.code,
          captchaId: verif.value,
          longitude: coord.lng,
          latitude: coord.lat
        })
        console.log("res", res)
        sessionStorage.removeItem('table_menu')
        setUser(res.Token, res.Id, res.Name, res.RoleId, res.IsMch, res.AgentCode, res.CodePath)
        updateOperators()
        navTo('Home')
        localStorage.setItem('user_info', JSON.stringify(res));
      } catch (e) {
        console.log("err", e)
      }
      // navTo('Home')
    }
    const changeVerifi = () => {

    }

    onMounted(() => {
      verifCode()
      const scale = () => {
        const detectZoom = () => {
          var ratio = 0;
          ratio = window.outerWidth / window.innerWidth;
          if (ratio) {
            ratio = Math.round(ratio * 100);
          }
          return ratio;
        }
        window.addEventListener('resize', () => {
          let ele = document.getElementById("loginbg")
          var ratio = 0;
          // console.log(JSON.stringify(window.innerWidth));

          if (window.screen.width < 700) {
            return;
          }
          ratio = window.outerWidth / window.innerWidth;
          // console.log(JSON.stringify(ratio));

          if (ratio) {
            ratio = Math.round(ratio * 100);
            ele.style.backgroundSize = detectZoom() + "%";
          }


        });
      }
      scale()
    })
    return {
      username,
      password,
      login,
      loginType,
      verif,
      verifCode,
      appname: cfg.appname
    }
  }
}
</script>

<style lang="scss" scoped>
.clear {
  clear: both;
}

.qrcode {
  width: 200px;
  height: 200px;
}

.qrcode-txt {
  display: block;
  text-align: center;
  font-size: 13px;
  margin-top: 10px;
  color: #333;
}

.login_bg {

  width: 100%;
  /* height: 100%; */
  min-width: 800px;

  background: url(../assets/img/login_bg_new.png) no-repeat center;
  background-size: 100%;
  background-color: #d7edfa;

}

.login_bg_new {
  position: absolute;
  /* position: relative; */
  z-index: -1;
}

.login_bg_new img {
  position: relative;
  /* position: absolute; */
  width: 100vw;
  height: 100vh;
  /* z-index: -1; */
}

.head_log {
  text-align: center;
  line-height: 20px;
  font-size: 26px;
}

.head_log p {
  color: #666;
  line-height: 40px;
  font-size: 12px;
  margin-bottom: 10px;
}

.login_bg2 {
  width: 100%;
  height: 100vh;
  /* max-width: 1000px; */
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login_bg3 {
  height: 100%;
  /* background: url('/login/bg.png'); */
  background-position: top right;
  background-repeat: no-repeat;
}

.login_bg2:after {
  display: block;
  content: '';
  clear: both;
}

.login_black_hg {
  background: #fff;
  border-radius: 6px;
  width: 350px;
  padding: 0px 40px 20px 40px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  /* Firefox */
  -webkit-box-sizing: border-box;
  /* Safari */
  padding-top: 40px;

  .verification {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}


.login_hg_left {
  flex: 1.8
}

.login_hg_right {
  /* background-color: pink; */
  flex: 1;
  /* margin-right:200px; */
  /* margin: auto; */
}

.company_logo {
  width: 100px;
}

.company_logo img {

  width: 350px;
  height: 100%;
  margin-bottom: 60px;

}



.login_input {
  margin: 20px 0 20px 0;
}

.login_btn {
  margin-top: 20px;
  width: 100%;
  font-size: 14px;
}

.must_rad {
  font-size: 12px;
}
</style>

<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">{{ $t("g.condition") }}</span>
      </div>
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t("g.create_at") }}</span>
          <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" size="small" type="daterange"
            unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
        </div>
        <div class="item">
          <span class="label">订单状态</span>
          <el-select class="inp" size="small" v-model="formData.valorderStatus" :placeholder="$t('g.choose')" clearable>
            <el-option v-for="item in render.orderStatus" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t('g.operator') }}</span>
          <!-- <el-select class="inp" size="small" v-model="formData.agentCode" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.operators" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select> -->
          <el-cascader size="small" style="width: 100%;flex: 1;" :clearable="true" :placeholder="$t('g.choose')"
            v-model="formData.agentCode" :props="formData.props" :options="render.cascade_options_direct" />
        </div>
        <div class="item">
          <span class="label">邀请状态</span>
          <el-select class="inp" size="small" v-model="formData.valinvitedStatus" :placeholder="$t('g.choose')"
            clearable>
            <el-option v-for="item in render.invitedStatus" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">邀请人号码</span>
          <el-input class="inp" size="small" v-model="formData.valphone" placeholder="邀请人号码"></el-input>
        </div>
        <div class="item">
          <span class="label">被邀请人号码</span>
          <el-input class="inp" size="small" v-model="formData.valphoneVisited" placeholder="被邀请人号码"></el-input>
        </div>
      </div>
      <div class="btn-ct">

        <!-- <el-button type="primary" @click="func.newActivity" class="new-btn" size="small">新建</el-button> -->

        <el-button @click="func.clear" class="search" type="info" size="small">{{ $t('g.clear') }}</el-button>
        <el-button @click="func.search" class="search" type="primary" size="small">{{ $t('g.search') }}</el-button>
        <!-- <el-button @click="func.clear" class="search" type="info" size="small">{{ $t('g.clear') }}</el-button>
        <el-button @click="func.search" class="search" type="primary" size="small">{{ $t('g.search') }}</el-button> -->
      </div>
    </div>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize
          }}</span>
        <div class="header-icons">
        </div>
      </div>
      <el-table :data="formData.tableData" :stripe="true" style="width: 100%" @row-click="func.showDetails">
        <el-table-column prop="Id" label="#" fixed :width="60">
        </el-table-column>
        <el-table-column prop="OrderNum" label="订单号" fixed :width="130">
        </el-table-column>
        <el-table-column prop="AgentCode" label="运营商" :width="80" v-if="isAdmin()"></el-table-column>
        <el-table-column prop="Status" label="订单状态" :width="130">
          <template #default="scope">
            <el-tag :type="scope.row.Status == 4 ? 'success' : 'warning'">{{
              render.orderStatus[scope.row.Status].label }}</el-tag></template>
        </el-table-column>
        <el-table-column prop="InviteState" label="邀请状态" :width="120">
          <template #default="scope">
              <el-tag v-if="scope.row.InviteState == 6" type="success">{{render.invitedStatus[scope.row.InviteState].label }}</el-tag>
              <el-tag v-else-if="scope.row.InviteState == 7" type="danger">{{render.invitedStatus[scope.row.InviteState].label }}</el-tag>
              <el-tag v-else-if="scope.row.InviteState == 5" type="primary">{{render.invitedStatus[scope.row.InviteState].label }}</el-tag>
              <el-tag v-else type="warning">{{render.invitedStatus[scope.row.InviteState].label }}</el-tag>
            </template>
        </el-table-column>
        <el-table-column prop="PullType" label="邀请人类型" :width="100"></el-table-column>
        <el-table-column prop="InviteUserId" label="邀请人Id" :width="80"></el-table-column>
        <el-table-column prop="User.Mobile" label="邀请人号码" :width="120"></el-table-column>
        <el-table-column prop="User.IdName" label="邀请人姓名" :width="100"></el-table-column>
        <el-table-column prop="PullSetId" label="拉新管理Id" :width="100"></el-table-column>
        <el-table-column prop="incomeMode" label="收益类型" :width="120">
          <template #default="scope">
            {{ render.awardType[scope.row.IncomeMode].label }}
          </template>
        </el-table-column>
        <el-table-column prop="FirstMonIncome" label="首次收益" :width="100">
          <template #default="scope">
            {{ scope.row.IncomeMode != 2 ? scope.row.FirstMonIncome : (scope.row.InviteFirstCoupon?.Value || 0) }}
          </template>
        </el-table-column>
        <el-table-column prop="LaterMonIncome" label="后续收益" :width="100">
          <template #default="scope">
            {{ scope.row.IncomeMode != 2 ? scope.row.LaterMonIncome : (scope.row.InviteLateCoupon?.Value || 0) }}
          </template>
        </el-table-column>
        <el-table-column prop="LaterMonIncomeNum" label="后续次数" :width="100"></el-table-column>
        <el-table-column prop="InvitedUserId" label="被邀请人Id" :width="100"></el-table-column>
        <el-table-column prop="NewUser.IdName" label="被邀请人姓名" :width="120"></el-table-column>
        <el-table-column prop="NewUser.Mobile" label="被邀请人号码" :width="130"></el-table-column>
        <el-table-column prop="InvitedIncomeValue" label="被邀请人收益" :width="120"></el-table-column>
        <el-table-column prop="InvitedCouponId" label="被邀请人优惠券Id" :width="140"></el-table-column>
        <el-table-column prop="InvitedComboOrderId" label="被邀请人订单Id" :width="140"
          :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="InvitedComboOrderTime" label="被邀请人订单时间" :width="140"
          :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="InvitedComboOrderNum" label="被邀请人订单数量" :width="140"></el-table-column>
        <el-table-column prop="EffectiveDate" label="截止日期" :width="180"></el-table-column>
        <el-table-column prop="CreatedAtStr" label="创建时间" :width="180"></el-table-column>
        <el-table-column prop="UpdatedAtStr" label="更新时间" :width="180"></el-table-column>
        <!-- <el-table-column :width="150" :label="$t('g.operation')" fixed="right">
          <template #default="scope">
            <el-tag size="large" type="danger" @click="func.del(scope.row.Id)">删除</el-tag>
            <el-tag size="large" type="success" style="margin-left: 1rem;"
              @click="func.editDialog(scope.row)">修改</el-tag>
          </template>
        </el-table-column> -->
      </el-table>

    </div>
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
        :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog :title="formData.operateType == 1 ? '新建' : '编辑'" v-model="formData.addElDailog" width="80%" center>
      <div class="dailog-content">
        <div class="activity-content">
          <el-form label-width="180px" :rules="rules" :model="formData">
            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('activityManage.title')" prop="name">
                  <el-input v-model="formData.Name" />
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="!isOperator()">
                <el-form-item :label="$t('g.operator')" prop="name">
                  <el-select class="inp" size="small" v-model="formData.AgentCode" :placeholder="$t('g.choose')">
                    <el-option v-for="item in render.operators" :key="item.value" :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="formData.addElDailog = false">取消</el-button>
          <el-button type="primary" @click="func.add">
            确认
          </el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog :title="$t('g.detail')" v-model="formData.dialogDisplayVisible" style="width:80%">
      <el-descriptions border>
        <el-descriptions-item label="Id">{{ formData.curData.Id }}</el-descriptions-item>
        <el-descriptions-item label="订单号">{{ formData.curData.OrderNum }}</el-descriptions-item>
        <el-descriptions-item label="运营商" v-if="isAdmin()">{{ formData.curData.AgentCode }}</el-descriptions-item>
        <el-descriptions-item label="订单状态"> <el-tag :type="formData.curData.Status == 4 ? 'success' : 'warning'">{{
          render.orderStatus[formData.curData.Status].label }}</el-tag></el-descriptions-item>
        <el-descriptions-item label="邀请状态">
            <el-tag v-if="formData.curData.InviteState == 6" type="success">{{render.invitedStatus[formData.curData.InviteState].label }}</el-tag>
            <el-tag v-else-if="formData.curData.InviteState == 7" type="danger">{{render.invitedStatus[formData.curData.InviteState].label }}</el-tag>
            <el-tag v-else-if="formData.curData.InviteState == 5" type="primary">{{render.invitedStatus[formData.curData.InviteState].label }}</el-tag>
            <el-tag v-else type="warning">{{render.invitedStatus[formData.curData.InviteState].label }}</el-tag>
        </el-descriptions-item>
        
        <el-descriptions-item label="邀请人Id">{{ formData.curData.InviteUserId }}</el-descriptions-item>
        <el-descriptions-item label="邀请人类型">{{ formData.curData.PullType }}</el-descriptions-item>
        <el-descriptions-item label="邀请人号码">{{ formData.curData.User?.Mobile }}</el-descriptions-item>
        <el-descriptions-item label="邀请人姓名">{{ formData.curData.User?.IdName }}</el-descriptions-item>
        <el-descriptions-item label="拉新管理Id">{{ formData.curData.PullSetId }}</el-descriptions-item>
        <el-descriptions-item label="收益类型">{{ render.awardType[formData.curData.IncomeMode].label }}
        </el-descriptions-item>
        <el-descriptions-item label="首次收益">

          {{ formData.curData.IncomeMode != 2 ? formData.curData.FirstMonIncome :
            formData.curData.InviteFirstCoupon?.Value
          }}</el-descriptions-item>
        <el-descriptions-item label="后续收益">{{ formData.curData.IncomeMode != 2 ?
          formData.curData.LaterMonIncome : formData.curData.InviteLateCoupon?.Value
          }}</el-descriptions-item>
        <el-descriptions-item label="后续次数">{{ formData.curData.LaterMonIncomeNum }}</el-descriptions-item>
        <el-descriptions-item label="被邀请人Id">{{ formData.curData.InvitedUserId }}</el-descriptions-item>
        <el-descriptions-item label="被邀请人姓名">{{ formData.curData.NewUser?.IdName }}</el-descriptions-item>
        <el-descriptions-item label="被邀请人号码">{{ formData.curData.NewUser?.Mobile }}</el-descriptions-item>
        <el-descriptions-item label="被邀请人收益">{{ formData.curData.InvitedIncomeValue }}</el-descriptions-item>
        <el-descriptions-item label="被邀请人优惠券Id">{{ formData.curData.InvitedCouponId }}</el-descriptions-item>

        <el-descriptions-item label="被邀请人订单数量">{{ formData.curData.InvitedComboOrderNum }}</el-descriptions-item>
        <el-descriptions-item label="截止日期">{{ formData.curData.EffectiveDate }}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{ formData.curData.CreatedAtStr }}</el-descriptions-item>
        <el-descriptions-item label="更新时间">{{ formData.curData.UpdatedAtStr }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions border column="1">
        <el-descriptions-item label="被邀请人订单Id">{{ formData.curData.InvitedComboOrderId }}</el-descriptions-item>
        <el-descriptions-item label="被邀请人订单时间">{{ formData.curData.InvitedComboOrderTime }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, onMounted, computed } from 'vue'
import { toDay } from '@/common/func'
import i18n from '@/data/i18n/index'
import { user, isOperator, isAdmin } from '@/data/token'
import { shortcuts, operators, cascade_options_direct } from '@/data/default'
import axios from "axios"
import { ElMessage, ElMessageBox, ElLoading, dayjs } from 'element-plus'

export default {
  name: 'recruitNew',
  setup() {
    const t = i18n.global.t
    const couponList = []
    const render = {
      shortcuts,
      couponList,
      statuses: [
        {
          label: t('device.disable'),
          value: 0
        },
        {
          label: t('device.enable'),
          value: 1
        }
      ],
      awardType: [
        {
          label: '现金固定',
          value: 0
        },
        {
          label: '现金百分比',
          value: 1
        },
        {
          label: '优惠券(天)',
          value: 2
        },
      ],
      VIPawardType: [
        {
          label: '现金固定',
          value: 0
        },
        {
          label: '现金百分比',
          value: 1
        },
        {
          label: '优惠券',
          value: 2
        }
      ],
      orderStatus: [
        {
          label: '待用户购买套餐',
          value: 0
        },
        {
          label: '待到账',
          value: 1
        },
        {
          label: '首次已到账',
          value: 2
        },
        {
          label: '后续到账',
          value: 3
        },
        {
          label: '全部已到账',
          value: 4
        },
      ],
      invitedStatus: [
        {
          label: '分享',
          value: 0
        },
        {
          label: '进入邀请',
          value: 1
        },
        {
          label: '已注册',
          value: 2
        },
        {
          label: '已实名',
          value: 3
        },
        {
          label: '已租电',
          value: 4
        },
        {
          label: '已购买套餐',
          value: 5
        },
        {
          label: '结束',
          value: 6
        },
        {
          label: '已过期',
          value: 7
        },
      ],
      operators,
      cascade_options_direct
    }
    const rules = reactive({
    })
    const formData = reactive({
      tableData: [],
      curPage: 1,
      pageSize: 10,
      total: 0,
      dialogDisplayVisible: false,
      props: {
        children: "Children", expandTrigger: 'hover',
        label: "Name",
        value: "CodePath",
        emitPath: false,
        checkStrictly: true,
      },
    })
    const func = {

      newActivity() {
        formData.operateType = 1
        formData.EnableMode = 1
        formData.addElDailog = true;
      },
      showDetails(row, column) {
        if (column && column.label == t('g.operation')) {
          return;
        }
        formData.dialogDisplayVisible = true;
        formData.curData = row;
      },
      async search() {
        func.loadData()
      },
      pageChange(val) {
        func.loadData(val)
      },
      async del(Id) {
        ElMessageBox.confirm(t('g.sure_delete'), {
          confirmButtonText: t('confirm'),
          cancelButtonText: t('cancel'),
        }).then(() => {
          try {
            axios.post('/admin/pullset/delete', {
              Id
            }).then((res) => {
              if (res == '操作成功') {
                ElMessage({
                  type: 'success',
                  message: t('g.success')
                })
              } else {
                ElMessage({
                  type: 'error',
                  message: t("err_code.20005")
                })
              }
              func.loadData()
            })

          } catch (error) {
            ElMessage({
              type: 'error',
              message: t("err_code.20005")
            })
          }

        })


      },
      clear() {
        formData.valphone = '';
        formData.agentCode = '';
        formData.valstatus = '';
        formData.valTime = '';
        formData.valinvitedStatus = '';
        formData.valorderStatus = '';
        func.loadData()
      },
      async add(id) {
        let params = {
          AgentCode: 'BJRD',
          PullSetId: 1,
          InviteState: 0,
          InviteUserId: 1866,
          InvitedUserId: 2524
        }
        let res
        if (formData.operateType == 2) {
          params.Id = formData.Id
          res = await axios.post('/admin/pullorder/edit', params);
        } else {
          res = await axios.post('/admin/pullorder/add', params);
        }
        console.log(JSON.stringify(res));

        if (res) {
          formData.addElDailog = false;
          func.loadData()
          ElMessage({
            type: 'success',
            message: t('g.success')
          })
        } else {
          ElMessage({
            type: 'error',
            message: t('err_code.20017')
          })
        }

      },
      async loadData(page = 1) {
        formData.curPage = page
        let params = {
          page,
        }
        if (formData.id) {
          params.id = formData.id
        }
        if (formData.valorderStatus) {
          params.Status = formData.valorderStatus
        }
        if (formData.valinvitedStatus) {
          params.InviteState = formData.valinvitedStatus
        }
        if (formData.valphone) {
          params.InvatePhoneNum = formData.valphone
        }
        if (formData.valphoneVisited) {
          params.InvatedPhoneNum = formData.valphoneVisited
        }
        if (formData.valTime) {
          params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
          params.endAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)
        }
        if (formData.agentCode) {
          params.CodePath = formData.agentCode
        }
        let res = await axios.get('/admin/pullorder/get', {
          params,
        })
        if (res) {
          formData.total = res.Total
          res.List.map((item) => {
            item.CreatedAtStr = toDay(item.CreatedAt)
            item.UpdatedAtStr = toDay(item.UpdatedAt)
            if(item.EffectiveDate){
              item.EffectiveDate = toDay(item.EffectiveDate)
            }
            if (item.PullType && item.PullSet) {
              // item.incomeMode = item.PullType == 'VIP' ? render.awardType[item.PullSet?.VipIncomeMode].label : render.awardType[item.PullSet?.IncomeMode].label
            }
          })
          formData.pageSize = res.PageSize
          formData.tableData = res.List
        }
      },
      handleChange() {
      },

    }
    onMounted(() => {
      func.loadData()
    })
    return {
      formData,
      func,
      formData,
      render,
      token: user.token,
      isOperator,
      isAdmin,
      rules,
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;
    }

    .inp-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;

      >.item {
        width: 49%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;

        .label {
          margin-right: 0.625rem;
          width: 4.375rem;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    >.pagination {
      text-align: right;
      margin-top: 1.25rem;
    }

  }

  .new-el-dialog {
    .dailog-content {
      display: flex;
    }

    .activity-content {
      flex: 2
    }

    // .activity-style {
    //   flex: 1;
    // }


  }

  :deep(.m-dialog) {
    // max-width: 80rem;
    margin-top: 0;
  }
}
</style>
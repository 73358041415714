export default {
  test:"test",
  appname:"Dandelion management system",
  tips:'tips',
  confirm:"confirm",
  cancel:"cancel",
  err_code:{
    201:"login failure",
    523:"user not registered",
    530:"please log in",
    531:"Please purchase or renew the package first",
    580:"There is an unpaid charge order, please pay first",
    581:"Support up to three charging orders",
    700:"The balance needs to be greater than $5 before charging",
    20000:"System error",
    20001:"json conversion error",
    20002:"This user has not yet bind a battery",
    20003:"Incoming parameter error",
    20004:"Failed to add data",
    20005:"Modification Failed",
    20006:"There are still menus or operations under the current module,cannot be deleted",
    20007:"Please input id",
    20008:"Please fill in the correct merchant number",
    20009:"This agent does not bind background users ",
    20010:"Please select Upload File",
    20011:"Uploading files is not valid",
    20012:"Directory creation failed",
    20013:"Battery creation failed",
    20014:"Charging cabinet creation failed",
    20015:"Data does not Exist",
    20016:"Adding failed, this type of package already exists",
    20017:"Creation Failed",
    20018:"This package cannot be found",
    20019:"Illegal Request",
    20020:"User name or password length is not valid",
    20021:"Data Already Exists",
    20022:"Password length is not valid, password length cannot be less than 6 characters",
    20023:"Special user cannot be deleted",
    20024:"This user is not bound to a permission list",
    20025:"Image suffix is not valid",
    20026:"This user does not bind a battery",
    20027:"User has not purchased the package",
    20028:"This agent equipment cannot be used",
    20029:"Device alarm, temporarily unavailable",
    20030:"Device disabled, temporarily unavailable",
    20031:"Package does not exist",
    20032:"Balance is insufficient",
    20033:"Deposit not set",
    20034:"Failed to deduct user balance",
    20035:"Failed to save user consumption log",
    20036:"Enough to buy a package, can not buy different types of packages",
    20037:"This battery deposit order does not exist",
    20038:"Wechat Pay initialization failed",
    20039:"No valid battery exists for this user",
    20040:"Device temporarily unavailable, unable to end charging",
    20041:"Non-maintenance personnel may not perform this operation",
    20042:"Wechat Pay callback failed",
    20043:"The time has not expired and cannot be deleted!",
  },
  chart:{
    keys:{
      "离线":"Offline",
      "缺电":"Lack of electricity",
      "锁仓":"Lock slot",
      "充满":"Fully charged",
      "闲置":"idle",
      "充电中":"Charging",
      "故障停用":"Fault off",
    },
    turnover_types:{
      wallet_charge:"Rechare",
      wallet_consume:"Consume",
      deposit:"Deposi",
      setmenu:"Power change package",
    },
    max_value:"Max",
    today:"Today",
    yesterday:"Yesterday",
    site_user_trend:"Network user Trend! Code scanning volume",
    num:"Amount",
    trends:{
      today:'Trend Today丨Live',
      turnover:"Trend Turnover",
      ebox:"Power exchange cabinet order cycle trend",
      cbox:"Charging Cabinet order cycle trends",
      pile:"Charging pile order cycle trends",
      consume:"trends in consumption",
    },
    order_ratio:"Order Ratio",
    order_types:{
      rent:"Rent",
      return:"Return",
      exchange:"Exchange ",
      cbox:"CBox",
      pile:"Pile",
      charge:"Recharge",
      setmenu:"SetMenu",
    },
    order_status:"Order status | real-time",
    order_statuses:{
      doing:"Doing",
      error:"Error",
      finished:"Finished",
    },
    devs_status:{
      ebox:"Power exchange cabinet equipment status | real-time",
      cbox:"Charging cabinet equipment status | real-time",
      pile:"Charging pile equipment status | real-time",
      cpbox:"hybrid equipment status | real-time",
    },
    dev_charge_status:{
      ebox:'Charging status of power exchange cabinet | real-time',
      cbox:"Charging status of charging cabinet | real-time",
      pile:"Charging status of charging pile | real-time",
      cpbox:"Hybrid device charging status | real-time",
    },
    unit_times:"Times",
    devs_rank:{
      err:"High frequency fault ranking | Top 30",
      offline:"High-frequency network disconnection ranking | Top 30",
      locked:"High-frequency lock-up slot rankings | Top 30",
      open:"Open position anomaly rankings | Top 30",
      charge:"Charging anomaly ranking | Top 30",
      frequence_high: "high-frequency equipment | Top 30",
      frequence_low:"low frequency use equipment | Top 30",
      lack:"Lack of electric cabinet group | Top 30",
    },
  },
  market:{
    color:{
      explain:"Color represents",
      types:{
        red:"Red",
        orange:"Orange",
        blue:"Blue",
      },
      means:{
        red:"High frequency",
        orange:"Medium frequency",
        blue:"Low frequency",
      }
    }
  },
  menu:{
    big_data:"Big data",
    decision:{
      analyze:"Decision analysis",
      hot:"Hot map",
      market:"Market analysis",
      operation:"Operation analysis",
      properties:"Asset analysis",
      order:"Order analysis",
      user:"User analysis",
      statistics:"data statistics",
    },
    distribution_site:"Sites distribution",
    distribution_battery:"电池分布",
    finance:"Finance",
    expenses_center:"Expenses",
    set_menu_manage:"Package settings",
    bill_manage:"Bill",
    turnover_record:"Turnover",
    settle:"Settle",
    refund:"Refund",
    badbill:"BadBill",
    refund_record:"Refund record",
    workOrder:"Work Order",
    workOrderList:"Work Order List",
    workOrderSet:"Work Order Set",
    orders:{
      manage:"Order management",
      rent:"Battery rent order",
      exchange:"Swapping battery order",
      return:"Returning battery order",
      cbox:"Charging cabinet order",
      pile:"Charging pile order",
      setmenu:"Battery swapping package",
      recharge:"Recharge order",
      offline:"Offline card purchase order",
      temporary_record:"Temporary record",
      import_record:"Import record",
    },
    operation:"Operation",
    member:"Member",
    profile:"Profile",
    member_list:"Members",
    agent_list:"Agents",
    after_sale_service:"After-Sale service",
    open_solot_record:"Open slot record",
    open_solot:"Warehouse opening record",
    electricity_stat:"电费统计",
    alert_record:"Alert record",
    activity_manage:"Event management",
    discount_coupon:'Coupon',
    award_manage:"Get the list",
    recruit_new:"inviting new users ",
    withdraw:"Withdrawal Management",
    restriction_rule:"Rule restrictions",
    feedback:"Feedback",
    sys:"System",
    log_info:"Logs",
    log_sys:"System logs",
    log_api:"Device logs",
    msg_log:"消息日志",
    properties:"Properties",
    box_manage:"Cabinet management",
    ebox_list:"Swapping cabinets",
    cbox_list:"Charging cabinet",
    cpbox_list:"Mixed cabinets",
    pile_list:"Piles",
    card_list:"Cards",
    pile_manage:"Pile management",
    battery_manage:"Battery management",
    card_manage:"Swipe Card management",
    battery_list:"Batteries",
    rights_manage:"Authority management",
    managers:"Managers",
    role_manage:"Roles",
    ExpensesBeOverdue:'Overdue management',
    overdue:"Overdue records",
    agents_switch:'CONTROL',
    SecondTierAgent:"First tier operator",
    ThirdTierAgent:"Second tier operator",
    FourthTierAgent:"Third tier operator",
  },
  common:{
    popup_titles:{
      t0:"Latest User Feedback",
      t1:"Major Alarm",
      t2:"Latest Order",
    },
    no_content:"No Content",
    rights:"© 2021 Shenzhen Modiary Co., Ltd.. All Rights Reserved",
    days:"D",
    hours:"H",
    minutes:"M",
    seconds:"S",
    not_support:"Not Support",
    coord_errs:{
      t0:"You refuse to share location information",
      t1:"Location information not available",
      t2:"Get location information timeout",
    },
    all:"All",
    out_of_sync:"Out of Sync.",
    dates:{
      last_week:"Last Week",
      last_month:"Last Month",
      last_quarter:"Last Quarter",
      future_week:"Next Week",
      future_month:"Next Month",
      future_quarter:"Next Quarter",
    },
  },
  g:{
    loading:"loading...",
    sure_delete:"Are You Sure To Delete?",
    to:"To",
    start_date:"Start Date",
    end_date:"End Date",
    sum:"Total",
    unit_num:"Total",
    per_page:"Each",
    see:"Search",
    total:"Total",
    minute:"Minute",
    operation:"Operation",
    search:"Search",
    clear:'Clear',
    abnormal:'异常',
    year:'年',
    month:'月',
    btn_confirm:"Confirm",
    btn_cancel:"Cancel",
    condition:"Condition",
    create_at:"Created At",
    update_at:"Updated At",
    battery_update_at:"电池更新时间",
    name:"Name",
    operator:"Operator",
    agent_code:"Agent Code",
    choose:"Please Choose",
    success:"Success",
    upload_failed:"Upload Failed",
    please_input:"please enter ",
    residue_degree:'Residue Degree',
    residue_days:'Days Remaining',
    yes:"Yes",
    no:"No",
    fei:'非',
    null:'Null',
    detail:"Detail",
    edit:"Edit",
    delete:"Delete",
    ms:"Background Management System",
    battery_status:"Battery status",
    stage:'temporary storage',
    stage_day:'Cumulative temporary storage days',
    staging_day:'Number of days in temporary storage',
    stage_month_num:'Number of temporary storages',
    operator_ownership:'Operator ownership',
    export:'export'
  },
  energy:{
    num:'当月电量',
    price:'当月电价',
    fee:'当月费用',
  },
  rights:{
    please_choose_deparment:"Please Choose Deparment",
    is_mch:"Whether it is a directly connected merchant",
    name:"Name",
    description:"Description",
    username:"Username",
    role:"Role",
    password:"Password",
    ph_psw:"If do not modify, do not fill",
    department:"Department",
    module_parent:"Parent Module",
    module_name:"Module Name",
    module_address:"Module Address",
    module_top:"---Top Module---",
    rights:"Rights",
    agent:"代理商",
    site_manager:"Site Manager",
    dev_ids:"Device Id",
    dev_ids_tips:"Multiple separated by commas",
    effective_time:"Effective Date",
    choose_time:"Choose Expiration Date",
  },
  order:{
    remark:"Remark",
    unit_times:"Times",
    unit_months:"Months",
    combo_type:"Type",
    end:"End",
    input_end_reason:"Please Fill In Reason",
    name:"Nickname",
    no:"NO.",
    price:"Money",//这里应该翻译为money
    failed_reason:"Failed Reason",
    end_reason:"End Reason",
    slot_get:"Got Slot",
    power_level:"Power",
    slot_return:"Returned Slot",
    site_name:"Site Name",
    pay_way:"Payment",
    back_way:"Refund Ways",
    unpayed:"Unpaid",
    ps:{
      //充电桩状态
      all:"All",
      unresponsive:"Unresponsive",
      charging:"Charging",
      full:"Full",
      finished:"Finished",
      depositing:"Depositing",
      prepare_failed:"Prepare Failed",
      failed:"Failed",
    },
    cs:{//充电柜状态
      all:"All",
      waiting:"Waiting",
      charging:"Charging",
      full:"Full",
      finished:"Finished",
      depositing:"Depositing",
      prepare_failed:"Prepare Failed",
      failed:"Failed",
      depositing_free:"Free Depositing",
    },
    took_battery:{
      no:"Number of taking out the battery",
      soc:"SOC of taking out the battery",
    },
    moneys:{
      charge:"Charge Price",
      deposit:"Deposit Price",
    },
    deposit_duration:"Deposti Duration(Minute)",
    charge_duration:"Charge Duration(Minute)",
    slot_id:"Slot Id",
    times:{
      charge_start:"Charge Start At",
      charge_end:"Charge End At",
      took_battery:"Took Battery At",
    },
    returned_battery_no:"Returned Battery NO.",
    slot_battery_before:"开仓前电池信息",
    pay_ways:{
      wechat:"Wechat",
      balance:"Balance",
      cash:'Cash',
      combo:'Combo',
      paypal:'Paypal',
      alipay:'Alipay',
    },
    returned:"Returned",
    imgs:{
      t0:"Image Open Empty Slot",
      t1:"Image Close Empty Slot",
      t2:"Image Open Battery Slot",
      t3:"Image Close Battery Slot",
    },
    inp:{
      name:"Please Input User Nickname",
      order_no:"Please Input Order NO.",
      device_no:"Please Input Device NO.",
    },
  },
  member:{
    cur_month_cost:"Cur Month Cost",
    cur_month_order_amount:"Cur Month Orders",
    month_card_expire:"Month Card About To Expire",
    expired_at:"Expired At",
    expired_day:"次数有效期(天)",
    expire:"Package expires",
    all_expire:"Total package expires",
    package_expired:"Package has expired",
    num_expired:'次数到期',
    is_expire:"Is the package effective",
    reg_at:"Reg At",
    agent_name:"Agent Name",
    is_vip:"名称",
    agent_name2:"Agent Name",
    agent_info:"Agent Info",
    IdNum:"Id number",
    IdCardimg:"ID photo",
    IdTime:"ID card validity period",
    IdName:"Name",
    wechat_rate:"Wechat Rate(%)",
    mobile:"Phone number",
    email:"邮箱",
    clear_mobile:"Recheck phone",
    clear_mobile_success:"Clear phone successfully",
    clear_mobile_fail:"Failed to re-verify phone",
    confirm_clear_mobile:"Confirm to re-verify mobile number",
    res_mobile_fail:",Need to re-verify after clearing",
    status:"Status",
    recharge:"Balance Recharge",
    rechargeAmount:"Please enter the recharge amount:",
    is_release:"release",
    user_status:'User Status',
    statuses:{
      all:"All",
      success:"Success",
      completed:"Completed",
      failed:"Failed",
      charging:"Charging",
      waiting:"Waiting",
      returned:"Returned",
      unpay:"Unpay",
      paid:"Paid",
    },
    order_status:{
      name:'Package status',
      all:'全部',
      rented:'在租',
      overdue:'逾期',
      new_rent:'新租',
      renew_lease:'续租',
      rent_out:'退租',
    },
    battery_status:'Bind battery',
    agent_code:"Agent Code",
    badBatteryNum:'Old battery ID',
    goodBatteryNum:'New battery ID',
    unBindBatteryNum:'Unbind battery ID',
    levelname:"Level name",
    level:"Level",
    username:"Username",
    nickname:"Nickname",
    coupon_day:"Coupon(day)",
    coupon_money:"Coupon(yuan)",
    detail:"Detail",
    mchid:"WeChat merchant account",
    mchid2:"wechat Merchant Number (payment related)",
    mchid_zfb:"Alipay merchant number",
    mchid2__zfb:"Alipay merchant number(payment related)",
    password:"WeChat merchant password (do not need to be changed to blank)",
    new_password:"New Password",
    new_password_ph:"Please enter a new password",
    repassword:"Repeat",
    repassword_ph:"Repeat the new password",
    repassword_not_same:"Repeat Password Not Same",
    modify_password:"Modify Password",
    balance:"Balance",
    avatar:"Avatar",
    bind_battery:"Replace battery",
    more_actions:"More",
    unbind_battery:"Unbind battery",
    maintainer_set:"Common",
    maintainer_unset:"Maintainer",
    forbidden:"Forbidden",
    overdue:"逾期用户",
    freeuser_set:"Free",
    freeuser_unset:"Not Free",
    bound_battery_no:"Bound Battery NO.",
    battery_no:"Battery NO.",
    err_money:"Wrong Money Number",
    input_battery_no:"Please Input Battery NO.",
    bind_success:"Bind Success",
    unbind_success:"unBind Success",
  },
  log:{
    trigger_time:"Trigger At",
    name_contain:"Name Contain",
    address_contain:"Address Contain",
    input_name:"Please Input Device Name",
    input_address:"Please Input Device Address",
    info:"Log Info",
    info_detail:"日志详情",
    type:"Type",
    types:{
      t0:"Power exchange cabinet Alarm",
      t1:"Power exchange cabinet failure",
      t2:"Charging Cabinet Alarm",
      t3:"Charging cabinet failure",
      t4:"Charging pile Alarm",
      t5:"Charging pile failure",
    },
  },
  decision:{
    type:"Type",
    cycle:"Cycle",
    time:"Time",
    poi:"POI",//地图poi
    stat_condition:"Condition",
    input_name:"Please Input Name",
    active_user:"Active User Amount",
    able_user:"Able User Amount",
    rent_income:"租电收益",
    combo_income:"套餐收益",
    charge_income:"充值收益",
    all_income:"总收益",
    tips:{
      type:"Please Select",
      status:"Please Select",
      num:"Please Input Amount",
      price:"Please Input Price",
      agent_code:"Please Select",
      correct_price:"Please Input Correct Price",
      correct_num:"Please Input Correct Number",
    },
    types:{
      distribution_device:"Devices Distribution",
      distribution_member:"Users Distribution",
      distribution_order:"Orders Distribution",
    },
    cycles:{
      by_year:"By Year",
      by_month:"By Month",
      by_day:"By Day",
    },
  },
  expenses:{
    name:"Name",
    type:"Type",
    num:"Amount",
    num_explain:"Quantity (corresponding to the number of months or times)",
    price:"Price",
    status:"Status",
    enable:"Enale",
    disable:"Disable",
    statuses:{
      all:"All",
      enabled:"Enabled",
      disabled:"Disabled",
    },
    types:{
      all:"All",
      by_month:"By Month",
      by_times:"By Times",
      by_day:"按天",
    },
  },
  bill:{
    total_charge:"Total Charge",
    total_consume:"Total Consume",
    total_deposit:"Total Deposit",
    sure_refund:"Are you sure you want a refund?",
    status:"Status",
    type:"Type",
    types:{
      all:"All",
      rent:"Rent order",//账单管理 => 流水记录
      cbox:"Electrical Cabinet charging Order",
      ebox:"Replacement Order",
      pile:"Charging pile Order",
      charge:"Prepaid Order",
      combo:"Purchase Order for Package",
      return:"Power return order",
      deposit:"Charging order deposit fee",
    },
    logTypes:{
      consume:"Consume",
      charge:"Charge",
      deposit:"Deposit",
    },
    refund_types:{
      deposit:"Deposit",
      balance:"Balance",
      bad_bill:"Bad Bill"
    },
    user:"User",
    name:"Name",
    nickname:"Nickname",
    user_nickname:"Nickname",
    operator:"Operator",
    opt_money:"Money",
    remark:"Reason",
    record_add:"Add",
    total:"Total",
    user_id:"User Id",
    no:"NO.",
    money:"Money",
    refunded:"Refuned",
    refund:"Refund",
    refund_money:"Refund Money",
    refunded_money:"Refunded Money",
    bill_date:"Bill Date",
    settle_count:"Settle Amount",
    money_received:"Money Received",
    money_bad_bill:"Money Bad Bill",
    settle_rate:"Settle Rate(%)",
    money_settle:"Settle Money",
    rs:{
      all:"All",
      waiting:"Waiting",
      refunding:"Refunding",
      refunded:"Refunded",
    },
    ph:{
      negative_as_cost:"Negative Number As Cost",
      user_id:"Return bill to member account, skip if not filled in",
      reason:"Please Input Reason"
    }
  },
  device:{
    day_order_num:"Daily Order Amount",
    week_order_num:"Week Order Amount",
    month_order_num:"Month Order Amount",
    charge_rule:"Charge Rule",
    add:"Add",
    delete:"Delete",
    power_gt:"When power is greater than",
    great_than:"Great Than",
    the_price:"The Price Is",
    price_unit:"$/hour",
    latitude:"latitude",
    longitude:"longitude",
    big_power_money:"price of super power",
    middle_power_money:"price of high power",
    small_power_money:"price of nomal power",
    big_power_range:"range of super power",
    middle_power_range:"range of high power",
    small_power_range:"range of nomal power",
    electric_money:"price/100watt",
    please_choose_status:"Please Choose Status",
    please_input_full_info:"Please Input Full Info",
    sure_open_slot:"Are You Sure To Open Slot?",
    sure_restart_dev:"Are you sure you want to restart the device?",
    cmd_sent:"Command sent, please wait",
    alert:"Alert",
    site_name:"Site Name",
    life_time:"LifeTime(Second)",
    update_time:"Last Updated At",
    temp:"Temperature",
    mac:"Mac",
    meas_power:"Power",
    electric_quantity:"Electricity",
    electric_price:"电量价格",
    coordinate:"Coordinate",
    img_dev:"Image Device",
    img_site:"Image Site",
    img_env:"Image Env.",
    slot_num_all:"Slot Amount",
    slot_num_empty:"Empty Slot Amount",
    slot_num_bat:"电池仓数",
    slot_num_error:"Abnormal Slot Amount",
    slot_num_box:"Charge Slot Amout",
    slot_num_pile:"Pile Amount",
    pile_num_all:"Pile Amount",
    pile_num_empty:"Empty Pile Amount",
    pile_num_error:"Abnormal Pile Amount",
    open_all_slots:"Open All Slots",
    reset:"Restart",
    close_heater:"Turn off heater",
    open_heater:"Turn on heater",
    slot_battery:"Battery Slot",
    slot_empty:"Empty Slot",
    slot_type:"Type",
    slot_warning:"Warning",
    slot_status:"Slot Status",
    device_id:'BatteryID',
    update_device_last:'Last Update BatteryID',
    specs:"Equipment Specifications",
    voltage:"Voltage",
    current:"Current",
    slot_no:"Slot NO.",
    pile_no:"Pile NO.",
    pile_type:"Type",
    pile_type_battery:"Battery Pile",
    pile_type_empty:"Empty Pile",
    sp_battery:"Battery",
    sp_empty:"Empty",
    sp_error:"Abnormal",
    open_slot:"Open Slot",
    enable:"Enable",
    disable:"Disable",
    address:"Device Address",
    slot_loading:"Loading...",
    pile_loading:"Loading...",
    name:"Device Name",
    type:"Device Type",
    pattern:"Device model",
    dev_id:"Device NO.",
    holder:"Holder",
    soc:"SOC",
    battery_soc:"Battery SOC",
    error_no:"Error NO.",
    charge_count:"Charge Count",
    status:"Status",
    operate_status:"Operation Status",
    online_status:"Online Status",
    deposit:"Deposit",
    deposit2:"Deposit",
    agent:"Agent",
    prices:{
      charge:"Charge Price / Hour",
      deposit:"Deposit / Hour",
      fine:"Find For Not Closing Slot",
    },
    moneys:{
      charge:"Charge Price",
      save:"Deposit",
      err_close:"Wrong Money Format",
    },
    isrent:'Is it available for rent',
    isreplaceable:'Is it return',
    free_deposit_time2:"Free storage time (minutes)",
    free_deposit_time:"Free storage time",
    statuses:{
      all:"All",
      charging:"Charging",
      full:"Full",
      normal:"Normal",
      enabled:"Enabled",
      disabled:"Disabled",
      online:"Online",
      offline:"Offline",
      enrent:'Available for rent',
      unrent:'Not available for rent',
      replaceable:'Return',
      unreplaceable:'Not return',
    },
    types:{
      all:"All",
      ebox:"Ebox",
      cbox:"Cbox",
      pile:"Pile",
      cpbox:"MixBox",
    },
    no:"NO.",
    holder:"Holder",
    power:"Power",
    slot_id:'Slot Id',
    soh:"SOH",
    volt:"Volt",
    cell_count:"Cell Count",
    protect:"Protect",
    input_current:"Input Current",
    charge_status:"Charge Status",
    input_volt:"Input Volt",
    inner_input_current:"Inner Input Current",
    env_temp:"Env. Temperature",
    cell_temp:"Cell Temperature",
    board_temp:"Board Temperature",
    move_status:{
      name:'运动状态',
      move:'移动',
      still:'静止',
      storage:'存储',
      hibernate:'休眠',
    },
    control_status:{
      name:'控制状态',
      discharge:'放电状态',
      charge:'充电状态',
      load:'负载在位状态',
      charged:'充电在位状态',
      empty:'空载状态',
    },
    net_status:{
      name:'联网状态',
      offline:'离线',
      online:'在线',
    },
    use_status:{
      name:'使用状态',
      idle:'空闲',
      cabinet:'在机柜',
      rider:'在骑手',
      uncabinet:'未上机',
    },
    rate:'速度(km/h)',
    deadline:'截止'
  },
  // 实体卡
  card:{
    number:"Number",
    deposit:'Deposit',
    bind_user:"Bind User",
    unbind_user:"Unbind user",    
    bind_package:"Bind Package",
    unbind:"Unbind",    
    renewal:"Renewal",
    accountType:"Account Type",    
    buyCombo:"Buy Combo",    
    cashRece:"Total cash received",    
    WeChat:"WeChat Mini Program",
    bindWeChat:"Bind WeChat Account",    
    Automatic:"Automatic",
    beforeExp:"Current Package expires",    
    afterExp:"Package expires after renewal",    
    expire:"expire",
    month:'Month',
    times:'Times',
    currency:'RMB',
    please_input_account:'User Account',
    binded_user:'Used',
    bind_tips:'Are you sure to bind to the [xx/xx] package for this user?',
    bind_status_no:'not bind'
  },
  openSlotsLit:{
      user_id:'User ID',
      dev_id:'Device ID',
      open_slots_type:'Opening Type',
      order_no:'Order No',
      open_slots_info:'Opening Info',
      open_slots_result:'Opening Results',
      agent_code:'Agent Code',
      site_name:'Site Name',
      slot_no:'Slot No',
      ins_battery_soc:'Insert Battery Soc',
      ins_battery_id:'Insert Battery Id',
      pull_out_battery_soc:'Pull out battery Soc',
      pull_out_battery_id:'Pull out battery Id',
      create_time:'Created Time',
      result:{
        all:'All',
        success:'Success',
        fail:'Fail'
      },
      types:{
        all:'All',
        all_slot:'All Slot',
        all_empty_slot:'All Empty Slot',
        all_battery_slot:'All Battery Slot',
        Specify_slot:'Specify Slot',
        full_charge_slot:'All fully charged',
      },
      no_data:"no data",
      export_details:'Export details',
      order_export:'Export order',
      loading:'Loading, please wait',
      opening_information_table:'OpeningInformationTable'
  },
  alertRecord:{
      OrderNum:'Order No',
      agent_code:'Agent Code',
      site_name:'Site Name',
      mac:'Device Mac',
      dev_id:'Device ID',
      phone_num_type:'Calling Number Type',
      phone_num_type_one:'Public',
      phone_num_type_two:'Special',
      dial_phone:'Calling Number',
      phones:'called number',
      type:'Fault Type',
      call_id:'Call Id',
      status_code:'Status Code',
      res:'Result',
      create_time:'Created Time',
      result:{
        all:'All',
        success:'Success',
        fail:'Fail'
      },
      alarm_information_table:'AlarmInformationTable'
  },
  activityManage:{
    title:'Activity name',
    time:'Activity time',
    start_time:'Starting time',
    end_time:'End Time',
    type:'Type of activity',
    activity_status:'Active status',
    push_type:'Push method',
    style:'Activity style',
    award_type:'Reward type',
    award_num:'Number of rewards',
    award_unit:'Award units',
    award_rule:'Activity Rules',
  },
  receive_list:{
    order_code:'Order code',
    coupon_code:'Coupon Code',
    receive_id:'ID',
    receive_info:'Get coupon information',
    Distributer:'Distributer',
    receive_id:'UserId',
    user_info:'Get user information',
    receive_time:'Collection time',
    status:'Collection status',
    use_channel:'Use channels',
    coupon_type:'Coupon type',
    use_time:'Usage time',
    remark:'Remark',
    coupon:'Coupon',
    face_value:'Denomination',
    face_num:'Denomination/The remaining amount',
    num:'Amount',
    remain_num:'The remaining amount',
    user_type:'User type',
    user:'User',
    channel:'Channel',
    withdraw:'withdraw',
    statuses:{
      unreceive:'Unaccalimed',
      unuse:'Unused',
      used:'Used',
      expired:'Expired',
      withdraw:'Withdrawn',
    },
    query:{
      current_status:'Current status'
    },
    receive_phone:'Recipient phone number',
    receive_channel:'Receipt channels',
    phone_placeholder:`Please enter the recipient’s mobile phone number. Please use half-width commas to separate multiple mobile phone numbers. For example:
13700000000,13800000000
Or use blank lines to split such as:
13700000000
13800000000
    `
  },
  coupon:{
    name:'Coupon name',
    type:'Type',
    face_value:'Denomination',
    coupon_issuance_method:'Coupon issuance method',
    circulation:'Circulation',
    actual_circulation:'Actual circulation',
    start_time:'Starting time',
    end_time:'Deadline',
    time:'Start and end time',
    remark:'Remark',
    status:'Status',
    rule:'rules',
    render:{
      type:{
        cash:'Cash coupon',
        time:'Lease extension coupon'
      },
      method:{
        new_user_register:'New User Registration',
        develop_new_user:'Develop new users',
        target:'Targeted issuance of coupons',
        combo_aotu:'Automatically issue coupons for packages',
        h5:'H5 link automatically received',
      }
    }
  },
  restriction_rule:{
    name:'Restricted name',
    description:"Limitation description",
    channel_restrict:"Channel restrictions",
    product_restrict:"Product restrictions",
    combo_restrict:"Package restrictions",

  },
  temporary_record:{
    status:'status',
    statuses:{
      failed:'Temporary storage failed',
      staging:'Temporary storage successfully',
      cancel_fail:'Destage failed',
      cancel_success:'Destage successfully'
    },
    start_time:'Temporary storage start time',
    release_time:'Temporary release time',
    days:'Days of temporary storage',
    remark:'Remark',
    extend_lease:'暂存延长租期',
    cabinet_sn:'暂存电柜SN号',
    Cangmen_no:'暂存电柜仓门编号',
    battery_sn:'暂存电池SN号',
    battery_soc:'暂存电池soc',
    remove_cabinet_sn:'解除暂存电柜SN号',
    remove_Cangmen_no:'解除暂存电柜仓门编号',
    remove_battery_sn:'解除暂存电池SN号',
    remove_battery_soc:'解除暂存电池soc',
    order:'Order number',
    change_time:'Change the time',
    expire_date:'Package expiration time',
    remove_expire_date:'Expiration time after cancellation',
  },
  login:{
    unlogin:"Unlogin",
    confirm_logout:"Are You Sure To Logout?",
    wechat_scan_to_login:"Wechat Scan",
    btn_login:"Login",
    verification_code:"验证码",
  },
  bigScreen:{
    title:'MDA management platform',
    all:'All',
    emotor:'Charging cabinet',
    ebox:'Swapping cabinet',
    cbox:'Vehicle',
    online :'Online',
    offline:'Offline',
    total_revenue:'Total revenue',
    daily_earnings:'Daily earnings',
    amount_received:'Amount received',
    today_income:"Today's income",
    refund_amount :'Refund amount',
    today_refund:"Today's refund",
    user_data:"User's data",
    registered_users:'Registered users',
    valid_users:'Valid users',
    added_users:'Added users',
    operation_data:'Operation',
    BAT_swapping:'Total BAT swapping',
    total_BAT_number:'Total BAT number',
    total_vehicle:'Total vehicle',
    success_rate_swapping:'Success rate of swapping',
    battery_rentals:'Number of rented batteries',
    vehicle_rentals:'Number of rented vehicle',
    unit:'Unit',
    not_active:'Not active',
    PCS:'pcs',
    total_battary:'Total number of batteries',
    total_emotor:'Total number of Vehicle',
    total_ebox:'Total number of swapping cabinet',
    
  },
  feedback:{
    time:"Feedback At",
    process:"Process",
    user:"User",
    content:"Content",
    reply:"Replay",
    error:"Error",
    statuses:{
      all:"All",
      undeal:"Undeal",
      done:"Done",
      doing:"Doing",
    },
    ph:{
      choose:"Please Choose",
      user:"Please Input Feedback User",
      content:"Please Input Feedback Content",
    }
  }
}

<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">{{ $t("g.condition") }}</span>
      </div>
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t('decision.time') }}</span>
          <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" size="small" type="daterange"
            unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
        </div>
        <!-- <div class="item" v-if="!isOperator()">
          <span class="label">{{ $t('g.operator') }}</span>
          <el-select class="inp" size="small" v-model="formData.valOperator" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.operators" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div> -->
        <div class="item">
          <span class="label">{{ $t('member.status') }}</span>
          <el-select class="inp" size="small" v-model="formData.valStatus" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.stage_statuses" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item" >
          <span class="label">{{ $t('g.operator') }}</span>
          <el-cascader size="small"  style="width: 100%;flex: 1;" :clearable="true" v-model="formData.valOperator" :props="formData.props" :options="render.cascade_options_direct" />
        </div>
        <!-- <div class="item">
          <span class="label">关联查下级</span>
          <el-select class="inp" size="small" v-model="formData.IsStartWith" :placeholder="$t('g.choose')">
            <el-option label="否" :value="0">
            </el-option>
            <el-option label="是" :value="1">
            </el-option>
          </el-select>
        </div> -->
        <div class="item">
          <span class="label">{{ $t('order.name') }}</span>
          <el-input class="inp" size="small" v-model="formData.valTel" :placeholder="$t('order.inp.name')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('order.no') }}</span>
          <el-input class="inp" size="small" v-model="formData.valOrderSn"
            :placeholder="$t('order.inp.order_no')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.no') }}</span>
          <el-input class="inp" size="small" v-model="formData.valDeviceId"
            :placeholder="$t('order.inp.device_no')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('member.mobile') }}</span>
          <el-input class="inp" size="small" v-model="formData.valPhone" :placeholder="$t('member.mobile')"></el-input>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary" size="small">{{ $t('g.search') }}</el-button>
      </div>
    </div>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize
          }}</span>
        <div class="header-icons">
          <!-- <i @click="func.loadData()" class="el-icon-download header-icon"></i> -->
          <el-icon @click="func.refresh()" class="header-icon">
            <Refresh />
          </el-icon>
        </div>
      </div>
      <el-table :data="formData.tableData" @row-click="func.showDetails" style="width: 100%">
        <el-table-column prop="StageOrder.Id" label="Id" width="80">
        </el-table-column>
        <el-table-column prop="UserInfo.Nickname" :label="$t('bill.nickname')" width="120">
        </el-table-column>
        <el-table-column prop="UserInfo.Mobile" width="120" :label="$t('member.mobile')">
        </el-table-column>
        <el-table-column prop="UserInfo.IdName" :label="$t('member.IdName')" width="120">
        </el-table-column>
        <el-table-column prop="UserInfo.AgentCode" :label="$t('g.operator')" width="120">
        </el-table-column>
        <el-table-column prop="NamePath" min-width="180" :label="$t('g.operator_ownership')" :show-overflow-tooltip='true'>
        </el-table-column>
        <el-table-column prop="StageOrder.OrderNum" :label="$t('temporary_record.order')" width="200">
        </el-table-column>
        <el-table-column prop="StageOrder.StageStatusStr" :label="$t('temporary_record.status')" width="200">
          <template #default="scope">
            <el-tag
              :type="scope.row.StageOrder.StageStatus == 0 ? 'danger' : (scope.row.StageOrder.StageStatus == 2 ? 'warning' : 'success')"
              effect="dark">
              {{ scope.row.StageOrder.StageStatusStr }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="StageAtStr" :label="$t('temporary_record.start_time')" width="180">
        </el-table-column>
        <el-table-column prop="UNStageAtStr" :label="$t('temporary_record.release_time')" width="180">
        </el-table-column>
        <el-table-column prop="StageOrder.StagedDate" :label="$t('temporary_record.days')" width="120">
        </el-table-column>
        <el-table-column prop="StageOrder.StageDevId" :label="$t('temporary_record.cabinet_sn')" width="260">
        </el-table-column><el-table-column prop="StageOrder.StageSlotId" :label="$t('temporary_record.Cangmen_no')"
          width="140">
        </el-table-column><el-table-column prop="StageOrder.StageBatId" :label="$t('temporary_record.battery_sn')"
          width="260">
        </el-table-column><el-table-column prop="StageOrder.StageBatSoc" :label="$t('temporary_record.battery_soc')"
          width="120">
        </el-table-column><el-table-column prop="StageOrder.UnStageDevId"
          :label="$t('temporary_record.remove_cabinet_sn')" width="260">
        </el-table-column><el-table-column prop="StageOrder.UnStageSlotId"
          :label="$t('temporary_record.remove_Cangmen_no')" width="180">
        </el-table-column><el-table-column prop="StageOrder.UnStageBatId"
          :label="$t('temporary_record.remove_battery_sn')" width="260">
        </el-table-column>
        <el-table-column prop="StageOrder.UnStageBatSoc" :label="$t('temporary_record.remove_battery_soc')" width="160">
        </el-table-column>

        <el-table-column prop="StageOrder.Remark" :label="$t('temporary_record.remark')" width="100">
        </el-table-column>
        <el-table-column prop="CreatedAtStr" width="180" :label="$t('g.create_at')">
        </el-table-column>
        <!-- <el-table-column
              width="80"
              :label="$t('member.status')">
              <template #default="scope">
                <el-tag
                    :type="scope.row.StatusStr==$t('member.statuses.success')?'success':(scope.row.StatusStr==$t('member.statuses.failed')?'danger':'warning')"
                    effect="dark">
                    {{ scope.row.StatusStr }}
                  </el-tag>
              </template>
            </el-table-column> -->
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
        :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog :title="$t('g.detail')" v-model="formData.dialogDisplayVisible" width="98%">
      <el-descriptions border>
        <el-descriptions-item :label="$t('bill.nickname')">{{ formData.curData.UserInfo.Nickname
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.IdName')">{{ formData.curData.UserInfo.IdName }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.operator')">{{ formData.curData.UserInfo.AgentCode }}</el-descriptions-item>
        <el-descriptions-item :label="$t('temporary_record.order')">{{ formData.curData.StageOrder.OrderNum
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('temporary_record.status')">
          <template #default="scope">
            <el-tag
              :type="formData.curData.StageOrder.StageStatus == 0 ? 'danger' : (formData.curData.StageOrder.StageStatus == 2 ? 'warning' : 'success')"
              effect="dark">
              {{ formData.curData.StageOrder.StageStatusStr }}
            </el-tag>
          </template>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('temporary_record.start_time')">{{ formData.curData.StageAtStr
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('temporary_record.release_time')">{{ formData.curData.UNStageAtStr
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('temporary_record.days')">{{ formData.curData.StageOrder.StagedDate
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('temporary_record.cabinet_sn')">{{ formData.curData.StageOrder.StageDevId
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('temporary_record.Cangmen_no')">{{ formData.curData.StageOrder.StageSlotId
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('temporary_record.battery_sn')">{{ formData.curData.StageOrder.StageBatId
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('temporary_record.battery_soc')">{{ formData.curData.StageOrder.StageBatSoc
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('temporary_record.remove_cabinet_sn')">{{
          formData.curData.StageOrder.UnStageDevId
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('temporary_record.remove_Cangmen_no')">{{
          formData.curData.StageOrder.UnStageSlotId
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('temporary_record.remove_battery_sn')">{{
          formData.curData.StageOrder.UnStageBatId
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('temporary_record.remove_battery_soc')">{{
          formData.curData.StageOrder.UnStageBatSoc }}</el-descriptions-item>

        <el-descriptions-item :label="$t('temporary_record.remark')">{{ formData.curData.StageOrder.Remark
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.create_at')">{{ formData.curData.CreatedAtStr }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted } from 'vue'
import axios from 'axios'
import { operators, updateOperators, shortcuts,cascade_options_direct } from '@/data/default'
import { toDay } from "@/common/func"
import i18n from '@/data/i18n/index'
import { isOperator } from '@/data/token'

export default {
  name: 'OrderBattery',
  setup() {
    const t = i18n.global.t
    const tableData = []
    const statuses = [
      {
        label: t('member.statuses.all'),
        value: -1
      },
      {
        label: t('member.statuses.success'),
        value: 1
      },
      {
        label: t('member.statuses.failed'),
        value: 0
      },
      {
        label: t('member.statuses.returned'),
        value: 2
      }
    ]
    const stage_statuses = [
      {
        label: t('member.statuses.all'),
        value: -1
      },
      {
        label: t('temporary_record.statuses.failed'),
        value: 0
      },
      {
        label: t('temporary_record.statuses.staging'),
        value: 1
      },
      {
        label: t('temporary_record.statuses.cancel_fail'),
        value: 2
      },
      {
        label: t('temporary_record.statuses.cancel_success'),
        value: 3
      },
    ]
    const render = {
      shortcuts,
      statuses,
      operators,
      stage_statuses,
      cascade_options_direct
    }
    const formData = reactive({
      tableData,
      curData: {},
      valOperator: 0,
      valStatus: -1,
      valTime: '',
      valTel: '',
      valOrderSn: '',
      valDeviceId: '',
      // pagination
      curPage: 1,
      pageSize: 10,
      total: 1,
      dialogDisplayVisible: false,
      props:{
          children:"Children",expandTrigger: 'hover',
          label:"Name",
          value:"CodePath",
          emitPath:false,
          checkStrictly:true
      },
      IsStartWith:1
    })
    const func = {
      showDetails(row) {
        formData.dialogDisplayVisible = true;
        formData.curData = row;
        console.log(JSON.stringify(row), 23);
      },
      search() {
        console.log('formData', formData)
        func.loadData(1)
      },
      pageChange(val) {
        console.log('page change', val)
        func.loadData(val)
      },
      refresh() {
        formData.valOperator = 0
        formData.valStatus = -1
        formData.valTime = ''
        formData.valTel = ''
        func.loadData(1)
      },
      diffdays(endTime, startTime) {
        let usedTime = endTime - startTime;
        let days = Math.floor(usedTime / (24 * 3600));
        let leavel = usedTime % (24 * 3600);
        let hours = Math.floor(leavel / (3600));
        let leavel2 = leavel % (3600);
        let minutes = Math.floor(leavel2 / (60));
        return days + '天' + hours + '时' + minutes + '分';
      },
      async loadData(page = 1) {
        try {
          formData.tableData = []
          formData.curPage = page
          let params = {
            page
          }
          if (formData.valOrderSn) {
            params.orderSn = formData.valOrderSn
          }
          if (formData.valDeviceId) {
            params.deviceId = formData.valDeviceId
          }
          if (formData.valPhone) {
            params.phoneNum = formData.valPhone
          }
          if (formData.valOperator) {
            params.agentcode = formData.valOperator
          }
          if (formData.valTel) {
            params.nickname = formData.valTel
          }
          if (formData.valStatus != -1) {
            params.status = formData.valStatus
          }
          // params.DeviceStatus = formData.valStatus
          if (formData.valTime) {
            params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
            params.endAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)
          }
          params.IsStartWith = formData.IsStartWith
          console.log(JSON.stringify(params));

          let res = await axios.post(`/admin/order/stage`, {
            ...params
          })
          // return;
          formData.total = res.Total
          res.List.map((item) => {
            item.CreatedAtStr = item.StageOrder?.CreatedAt ? toDay(item.StageOrder.CreatedAt) : 0
            item.StageAtStr = item.StageOrder?.StageTime ? toDay(item.StageOrder.StageTime) : '-'
            item.UNStageAtStr = item.StageOrder?.UnStageTime ? toDay(item.StageOrder.UnStageTime) : '-'
            if (item.StageOrder?.StagedDay && item.StageOrder?.StageTime && item.StageOrder?.UnStageTime) {
              // item.StageOrder.StagedDate = toDay(item.StageOrder.UnStageTime-item.StageOrder.StagedDay)
              item.StageOrder.StagedDate = func.diffdays(item.StageOrder.UnStageTime, item.StageOrder.StageTime)

            }
            switch (item.StageOrder.StageStatus) {
              case 0:
                item.StageOrder.StageStatusStr = t('temporary_record.statuses.failed');
                break;
              case 1:
                item.StageOrder.StageStatusStr = t('temporary_record.statuses.staging');
                break;
              case 2:
                item.StageOrder.StageStatusStr = t('temporary_record.statuses.cancel_fail');
                break;
              case 3:
                item.StageOrder.StageStatusStr = t('temporary_record.statuses.cancel_success');
                break;
            }

          })
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        } catch (e) {
          console.log("loadData", e)
        }
      }
    }
    onMounted(() => {
      console.log("onMounted")
      //updateOperators() 
      func.loadData()
    })
    return {
      func,
      render,
      formData,
      isOperator,
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;

  .inp-block {
    display: block;
  }

  .table-expand {
    padding-left: 4rem;
    padding-top: 1rem;
  }

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }

      >.header-icons {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;

        .header-icon {
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color: #969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    .inp-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;

      >.item {
        width: 49%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;

        .label {
          margin-right: 0.625rem;
          width: 4.375rem;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }
  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  >.data-table {
    :deep(.el-table) {
      .cell {
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .el-table__row {
        cursor: pointer;
      }
    }
  }

  >.pagination {
    text-align: right;
    margin-top: 1.25rem;
  }

  .el-form-row {
    display: flex;
    justify-content: space-between;

    :deep(.el-form-item) {
      width: 49%;
    }
  }

  .el-select-my {
    text-align: left;
    display: block;
  }

  :deep(.m-dialog) {
    max-width: 80rem;
  }
}
</style>

<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">{{ $t("g.condition") }}</span>
      </div>
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t("g.create_at") }}</span>
          <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" size="small" type="daterange"
            unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
        </div>
        <div class="item">
          <span class="label">{{ $t('member.agent_name') }}</span>
          <el-input class="inp" size="small" v-model="formData.valName"
            :placeholder="$t('member.agent_name')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('member.status') }}</span>
          <el-select class="inp" size="small" v-model="formData.valStatus" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.statuses" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item" v-if="!isOperator()">
          <span class="label">{{ $t('member.agent_code') }}</span>
          <el-input class="inp" size="small" v-model="formData.valCode"
            :placeholder="$t('member.agent_code')"></el-input>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary" size="small">{{ $t('g.search') }}</el-button>
      </div>
    </div>
    <div class="card-box data-table">
      <div class="header header_flex">
        <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize
          }}</span>
        <el-button @click="func.add_show" type="primary" :icon="Plus">新增</el-button>
      </div>
      <el-table @row-click="func.showDetails" :data="formData.tableData" style="width: 100%">
        <el-table-column prop="Id" label="#" width="80">
        </el-table-column>
        <el-table-column prop="Name" :label="$t('member.agent_name')" width="140">
        </el-table-column>
        <el-table-column prop="Code" v-if="!isOperator()" :label="$t('member.agent_code')" width="100">
        </el-table-column>
        <el-table-column prop="Username" :label="$t('member.username')" width="160">
        </el-table-column>
        <el-table-column prop="LevelName" :label="$t('member.levelname')" width="120">
        </el-table-column>
        <!-- <el-table-column prop="CurMonthOrderAmount" width="180" :label="$t('member.cur_month_order_amount')">
        </el-table-column>
        <el-table-column prop="CurMonthCost" width="180" :label="$t('member.cur_month_cost')">
        </el-table-column> -->
        <el-table-column prop="Mchid" width="120" :label="$t('member.mchid')">
        </el-table-column>
        <el-table-column prop="AliAppid" width="160" :label="$t('member.mchid_zfb')">
        </el-table-column>
        <el-table-column width="160" :label="$t('member.status')">
          <template #default="scope">
            <el-tag :type="scope.row.IsValid == 1 ? 'success' : 'info'" effect="dark">
              {{ scope.row.IsValid == 1 ? $t('expenses.statuses.enabled') : $t('expenses.statuses.disabled') }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="CreateAtStr" min-width="180" :label="$t('g.create_at')">
        </el-table-column>
        <el-table-column width="100" :label="$t('g.operation')" fixed="right">
          <template #default="scope">
            <el-tag @click="func.rowDblClick(scope.row)">{{ $t('g.edit') }}</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
        :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog :title="$t('g.detail')" v-model="formData.dialogDisplayVisible" width="60%">
      <el-descriptions border>
        <el-descriptions-item label="Id">{{ formData.curData.Id }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.agent_name')">{{ formData.curData.Name }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.agent_code')" v-if="!isOperator()">{{ formData.curData.Code
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.username')">{{ formData.curData.Username }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.levelname')">{{ formData.curData.LevelName }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.cur_month_order_amount')">{{ formData.curData.CurMonthOrderAmount
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.cur_month_cost')">{{ formData.curData.CurMonthCost
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.mchid')">{{ formData.curData.Mchid }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.mchid_zfb')">{{ formData.curData.AliAppid }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.status')">
          <el-tag :type="formData.curData.IsValid == 1 ? 'success' : 'info'" effect="dark">
            {{ formData.curData.IsValid == 1 ? $t('expenses.statuses.enabled') : $t('expenses.statuses.disabled') }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('g.create_at')">{{ formData.curData.CreateAtStr }}</el-descriptions-item>

      </el-descriptions>
    </el-dialog>
    <el-dialog :title="formData.EditType == 'edit' ? '修改' : '新增'" v-model="formData.dialogVisible" width="85%">
      <!-- label-width="80px" -->
      <el-form :model="formData.curData" label-width="116px">
        <!-- <div class="el-form-row">
          <el-form-item label="代理商名称">
            <el-input size="small"  v-model="formData.curData.name"></el-input>
          </el-form-item>
          <el-form-item label="代理商编号">
            <el-input size="small"  v-model="formData.curData.code" oninput="value=value.replace(/[^a-zA-Z]/g, '')"  maxlength="4" placeholder="请输入不超过4个字母的英文编码,如曹操出行输入cccx"></el-input>
          </el-form-item>
        </div> -->
        <div class="el-form-row">
          <el-form-item :label="$t('member.status')" >
            <el-select class="inp" size="small" v-model="formData.curData.isValid" :placeholder="$t('g.choose')">
              <el-option v-for="item in render.updateStatuses" :key="item.value" :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="微信商户号">
            <el-input size="small" type="number" v-model="formData.curData.mchid" placeholder="支付相关微信商户"></el-input>
          </el-form-item>
        </div>
        <div class="el-form-row">
          <el-form-item :label="$t('member.mchid_zfb')">
            <el-input size="small" type="number" v-model="formData.curData.AliAppid"></el-input>
          </el-form-item>
          <el-form-item label="微信商户号密码">
            <el-input size="small" type="password" v-model="formData.curData.password" placeholder="不需要修改不填"></el-input>
          </el-form-item>
        </div>

      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="func.updateData()">{{ $t('g.btn_confirm') }}</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import { reactive, onMounted, computed } from 'vue'
import { shortcuts, updateOperators } from '@/data/default'
import { toDay } from '@/common/func'
import axios from "axios"
import { ElMessage } from 'element-plus'
import i18n from '@/data/i18n/index'
import { isOperator } from '@/data/token'
import { Plus } from '@element-plus/icons-vue'
export default {
  name: 'ExpensesAgents',
  setup() {
    const t = i18n.global.t
    const tableData = []
    const statuses = [
      {
        label: t('expenses.statuses.all'),
        value: 0
      },
      {
        label: t('expenses.statuses.enabled'),
        value: 1
      },
      {
        label: t('expenses.statuses.disabled'),
        value: 2
      }
    ]

    const render = {
      statuses,
      updateStatuses: [
        {
          label: t('expenses.enable'),
          value: 1
        },
        {
          label: t('expenses.disable'),
          value: 0
        }
      ],
      shortcuts
    }
    const formData = reactive({
      tableData,
      curData: {},
      valTime: '',
      valStatus: 0,
      dialogVisible: false,
      dialogDisplayVisible: false,
      curPage: 1,
      pageSize: 10,
      total: 500,
      valName: '',
      valCode: '',
      EditType: 'edit'
    })
    const func = {
      showDetails(row, column) {
        if (column && column.label == t('g.operation')) {
          return;
        }
        formData.dialogDisplayVisible = true;
        formData.curData = row;
      },
      search() {
        console.log('formData', formData)
        func.loadData()
      },
      add_show() {
        formData.EditType = 'add'
        formData.curData = {}
        formData.dialogVisible = true
      },
      rowDblClick(row, column, e) {
        console.log('rowDblClick', row, column, e)
        formData.EditType = 'edit'
        formData.curData = row
        formData.curData.mchid = row.Mchid
        formData.curData.isValid = row.IsValid
        formData.dialogVisible = true
      },
      pageChange(val) {
        console.log('page change', val)
        func.loadData(val)
      },
      showAddDataDialog() {
        formData.curData = {
        }
        formData.dialogVisible = true
      },
      async updateData() {
        console.log("updateOrCreate Data", formData.curData)
        // if (typeof (formData.curData.Status) == 'undefined') {
        //   ElMessage({
        //     type: 'error',
        //     message: t('device.please_choose_status')
        //   })
        //   return
        // }

        try {
          const params = {
            mchid: formData.curData.mchid,
            isValid: formData.curData.isValid,
            password: formData.curData.password,
          }
          if(formData.curData.id){
            params.id = formData.curData.id
          }
          console.log(params)
          await axios.put('/admin/agent', params)
          //await axios.put('/admin/agent/addagent', params)
        } catch (e) {
        }
        formData.dialogVisible = false
        func.loadData(formData.curPage)
        //更新reactive数据
        updateOperators()
      },
      async loadData(page = 1) {
        try {
          formData.tableData = []
          formData.curPage = page
          let params = {
            page,
          }
          if (formData.valStatus !== '') {
            params.isValid = formData.valStatus
          }
          if (formData.valName) {
            params.name = formData.valName
          }
          if (formData.valCode) {
            params.code = formData.valCode
          }
          console.log(JSON.stringify(params));

          if (formData.valTime) {
            params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
            params.endAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)
          }
          let res = await axios.get(`/admin/agent`, {
            params
          })
          formData.total = res.Total
          res.List.map((item) => {
            item.CreateAtStr = toDay(item.CreateAt)
            item.CurMonthCost = item.CurMonthCost?.toFixed(2) || 0
          })
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        } catch (e) {
          console.log("loadData", e)
        }
      }
    }
    onMounted(() => {
      func.loadData()
    })
    return {
      func,
      render,
      formData,
      isOperator,
      Plus
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }

      >.header-icons {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;

        .header-icon {
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color: #969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    .inp-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;

      >.item {
        width: 49%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;

        .label {
          margin-right: 0.625rem;
          width: 4.375rem;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }
  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  >.data-table {
    :deep(.el-table) {
      .cell {
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .el-table__row {
        cursor: pointer;
      }
    }
  }

  >.pagination {
    text-align: right;
    margin-top: 1.25rem;
  }

  .el-form-row {
    display: flex;
    justify-content: space-between;

    :deep(.el-form-item) {
      width: 49%;
    }

    .inp {
      width: 100%;
    }
  }

  .el-select-my {
    text-align: left;
    display: block;
  }

  :deep(.m-dialog) {
    max-width: 50rem;
  }
}

.header_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width:800px) {
  .content {
    >.card-box {
      .inp-list {
        >.item {
          width: 100%;
        }
      }
    }
  }
}
</style>

<template>
  <div class="content">
    <div class="card-box data-table">
      <div class="header">
        <div class="header-icons">
          <!-- <i @click="func.loadData()" class="el-icon-download header-icon"></i> -->
		  <!-- <el-icon @click="func.add()" class="header-icon"><Plus /></el-icon> -->
          <el-button @click="func.add" type="primary"  :icon="Plus">新增</el-button>
        </div>
      </div>
      <el-table
            :data="formData.tableData"
            style="width: 100%">
            <el-table-column
              prop="Id"
              label="#"
              width="80">
            </el-table-column>
            <el-table-column
              prop="Title"
              :label="$t('rights.name')"
              width="120">
            </el-table-column>
            <el-table-column
              prop="Description"
              :label="$t('rights.description')"
              min-width="200">
            </el-table-column>
            <el-table-column
              prop="Description"
              label="角色创建来源"
              min-width="200">
              <template #default="scope">
                  <span>
                    {{ scope.row.AgentCode==""?'平台':scope.row.AgentCode }}
                  </span>
              </template>
            </el-table-column>
            <el-table-column
              width="120"
              :label="$t('expenses.status')">
              <template #default="scope">
                <el-tag
                    :type="scope.row.Status==1?'success':'info'"
                    effect="dark">
                    {{ scope.row.Status==1?$t('expenses.statuses.enabled'):$t('expenses.statuses.disabled') }}
                  </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              width="220"
              :label="$t('g.operation')">
              <template #default="scope">
                <el-tag
                  @click="func.showRights(scope.row)"
                  type="success"
                >{{$t('rights.rights')}}</el-tag>
                <el-tag
                  class="btn-del"
                  @click="func.edit(scope.row)"
                >{{$t('g.edit')}}</el-tag>
                <el-tag
                  class="btn-del"
                  @click="func.del(scope.row)"
                  type="warning"
                >{{$t('g.delete')}}</el-tag>
              </template>
            </el-table-column>
          </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="func.pageChange"
        :page-size="formData.pageSize"
        :current-page="formData.curPage"
        :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog
      title="角色"
      v-model="formData.dialogVisible"
      width="70%">
      <el-form  label-width="80px" :model="formData.curData">
        <div class="el-form-row">
          <el-form-item :label="$t('rights.name')">
            <el-input v-model="formData.curData.Title"></el-input>
          </el-form-item>
          <el-form-item :label="$t('rights.description')">
            <el-input v-model="formData.curData.Description"></el-input>
          </el-form-item>
          <el-form-item label="角色归属" v-if="!isOperator()">
            <el-select class="inp"  v-model="formData.curData.optValue" :placeholder="$t('g.choose')" :clearable="true">
              <el-option v-for="item in render.operatorList" :key="item.id" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="default" @click="formData.dialogVisible = false">{{$t('g.btn_cancel')}}</el-button>
          <el-button type="primary" @click="func.save()">{{$t('g.btn_confirm')}}</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog
      :title="$t('tips')"
      v-model="formData.rightsDialogVisible"
      width="60%">
      <div class="cbs">
        <div class="item" v-for="(item,index) in formData.rights" :key="index">
          <el-checkbox @change="func.topChange(item)" class="cb" v-model="item.Checked" :label="item.ModuleName"></el-checkbox>
          <div class="sub" v-for="(item2,index) in item.AccessItem" :key="index">
            <el-checkbox @change="func.subChange(item,item2,item.AccessItem)" class="cb" v-model="item2.Checked" :label="item2.ModuleName"></el-checkbox>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="default" @click="formData.rightsDialogVisible = false">{{$t('g.btn_cancel')}}</el-button>
          <el-button type="primary" @click="func.setRights()">{{$t('g.btn_confirm')}}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive,onMounted } from 'vue'
import axios from 'axios'
import { shortcuts,operatorList} from "@/data/default"
import { toDay } from "@/common/func"
import i18n from '@/data/i18n/index'
import { ElMessageBox } from 'element-plus'
import { isOperator } from '@/data/token'
import { Plus } from '@element-plus/icons-vue'
export default {
  name: 'RightsRole',
  setup () {
    const t  = i18n.global.t
    const tableData = [
    ]
    const render = reactive({
      shortcuts,
      optlist:[],
      operatorList
    })
    const formData = reactive({
      tableData,
      curData: {
        optValue:''
      },
      valTime: '',
      valDevId: '',
      valName: '',
      dialogVisible: false,
      rightsDialogVisible: false,
      curPage: 1,
      rights:[],
      pageSize: 10,
      total: 100,
      valAddress: '',
      user_info:{},
      roleList:[],
    })
    const func = {
      search () {
        console.log('formData', formData)
        func.loadData()
      },
      edit(row){
        formData.curData = Object.assign({},row)
        formData.curData.optValue = row.AgentCode
        formData.dialogVisible = true
      },
      topChange(row){
        for(let item of row.AccessItem){
          item.Checked = row.Checked
        }
      },
      subChange(top,row,data){
        if(row.Checked){
          top.Checked = true
        }
        if(row.Checked == false){
          let allFalse = data.every(item => !item.Checked);
          if (allFalse) {  
            top.Checked = false;  
          }  
        }
      },
      async showRights(row){
        formData.curData = Object.assign({},row)
        formData.rights = []
        let res = await axios.get('/admin/role/auth?id='+row.Id)
        let rights = []
        if(formData.user_info.RoleId == 25 || formData.user_info.RoleId == 26){
          for(let i=0;i<formData.roleList.length;i++){
              if(formData.roleList[i].Checked&&formData.roleList[i].Id!=84){
                let tmp = {
                  Id:formData.roleList[i].Id,
                  ModuleName:formData.roleList[i].ModuleName,
                }
                if(res[i].Checked){
                  tmp.Checked = true
                }else{
                  tmp.Checked = false
                }
                tmp.AccessItem = []
                for(let j=0;j<formData.roleList[i].AccessItem.length;j++){
                  if(formData.roleList[i].AccessItem[j].Checked&&formData.roleList[i].AccessItem[j].Id != 164&&formData.roleList[i].AccessItem[j].Id != 165&&formData.roleList[i].AccessItem[j].Id != 166){
                    tmp.AccessItem.push({
                      Id:formData.roleList[i].AccessItem[j].Id,
                      ModuleName:formData.roleList[i].AccessItem[j].ModuleName,
                      Checked:res[i].AccessItem[j].Checked ? true : false
                    })
                  }
                }
                rights.push(tmp)
              }
          }
        }else{
          for(let item of res){
            let tmp = {
              Id:item.Id,
              ModuleName:item.ModuleName,
              Checked:item.Checked,
            }
            if(item.AccessItem){
              tmp.AccessItem = []
              for(let item2 of item.AccessItem){
                tmp.AccessItem.push({
                  Id:item2.Id,
                  ModuleName:item2.ModuleName,
                  Checked:item2.Checked,
                })
              }
            }
            rights.push(tmp)
          }
        }

        formData.rights = rights
        formData.rightsDialogVisible = true
      },
      async setRights(){
        let rights = ""
        for(let top of formData.rights){
          if(top.Checked)rights+=","+top.Id;
          for(let sub of top.AccessItem){
            if(sub.Checked)rights+=","+sub.Id;
          }
        }
        if(rights){
          rights = rights.substr(1)
        }
        console.log(formData.curData.Id,rights)
        await axios.post('/admin/role/doAuth',{
          role_id:formData.curData.Id,
          access_node:rights,
        })
        formData.rightsDialogVisible = false
      },
      async del(row){
        try{
          await ElMessageBox.confirm(
              t('g.sure_delete'),
              t('tips'),
              {
                confirmButtonText: t('confirm'),
                cancelButtonText: t('cancel'),
                type: 'warning',
              }
            )
          await axios.post("/admin/role/delete",{
            id:row.Id
          })
          func.loadData()
        }catch(e){
          console.log("del",e)
        }
      },
      async save(){
        const data = formData.curData
        let params = {
          title:data.Title,
          description:data.Description,
          AgentCode:data.optValue
        }
        if(data.Id){
          params.id = data.Id
          await axios.post("/admin/role/doEdit",params)
        }else{
          await axios.post("/admin/role/doAdd",params)
        }
        func.loadData()
        formData.dialogVisible = false
      },
      add(){
        formData.curData = {
          optValue:''
        }
        formData.dialogVisible = true
      },
      rowDblClick (row, column, e) {
        console.log('rowDblClick', row, column, e)
        formData.curData = row
        formData.dialogVisible = true
      },
      pageChange (val) {
        console.log('page change', val)
        func.loadData(val)
      },
      async loadData(page = 1){
        try{
          formData.tableData = []
          formData.curPage = page
          let res = await axios.get(`/admin/role`,{
            params:{page}
          })
          console.log(res,'角色列表')
          formData.total = res.Total
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        }catch(e){
          console.log("loadData",e)
        }
      },
      async get_role_list(){
        let res = await axios.get('/admin/role/auth?id='+formData.user_info.RoleId)
        // let list = []
        // for(let obj of res){
        //     if(obj.Checked){
        //       let tmp = {
        //         Id:obj.Id,
        //         ModuleName:obj.ModuleName,
        //         Checked:obj.Checked,
        //       }
        //       tmp.AccessItem = []
        //       for(let obj2 of obj.AccessItem){
        //         if(obj2.Checked){
        //           tmp.AccessItem.push({
        //             Id:obj2.Id,
        //             ModuleName:obj2.ModuleName,
        //             Checked:obj2.Checked,
        //           })
        //         }
                
        //       }
        //       list.push(tmp)
        //     }
        //   }
        formData.roleList = res
      },
      async get_opt (){
        let list =  await new Promise((resolve,reject)=>{
          resolve(operatorList);
        });
        setTimeout(()=>{
          list.unshift({label:'平台',value:''})
          render.optlist = list
        },200)
        
      }
    }
    onMounted(()=>{
      func.loadData()
      if(localStorage.getItem('user_info')){
        formData.user_info = JSON.parse(localStorage.getItem('user_info'))
        if(formData.user_info.RoleId != 14){
          func.get_role_list()
        }
        
      }
      //func.get_opt()
      console.log(isOperator(),'测试123')

    })
    return {
      func,
      render,
      formData,
      isOperator,
      Plus
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;
  .inp2{
    width: 100%;
  }
  .btn-del{
    margin-left: 0.5rem;
  }
  .cbs{
    .cb{
    }
    >.item{
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 1rem;
      >.cb{
        width: 100%;
        text-align: left;
        margin-bottom: 0.5rem;
      }
      >.sub{
        margin-left: 1rem;
      }
    }
  }
  >.card-box{
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;
    .header{
      text-align:left;
      margin-bottom: 1.25rem;
      position: relative;
      >.title{
        font-size:1rem;
        font-weight: 500;
      }
      >.header-icons{
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;
        .header-icon{
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color:#969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }
    >.btn-ct{
      text-align: right;
      :deep(.el-button--small){
        padding: 0.625rem 2rem;
      }
    }
    .inp-list{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      >.item{
        width: 49%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;
        .label{
          margin-right: 0.625rem;
          width: 4.375rem;
        }
        :deep(.el-range-editor--small.el-input__inner){
          flex: 1;
        }
        .inp{
          flex: 1;
        }
      }
    }
  }
  >.el-alert-my{
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;
    .el-alert-my-txt{
      font-size: 0.875rem;
      >.tips{
        font-weight: bold;
      }
    }
    :deep(.el-alert__closebtn){
      top: 1.25rem;
      font-size: 1rem;
    }
  }
  >.data-table{
    :deep(.el-table){
      .cell{
        white-space: normal;
        text-overflow: ellipsis;
      }
      .el-table__row{
        cursor: pointer;
      }
    }
  }
  >.pagination{
    text-align: right;
    margin-top: 1.25rem;
  }
  .el-form-row{
    display:flex;
    justify-content: space-between;
    :deep(.el-form-item){
      width: 49%;
    }
  }
  :deep(.m-dialog){
    max-width: 50rem;
  }
}
@media screen and (max-width:800px) {
    .content{
      >.card-box{
        .inp-list{
          >.item{
            width: 100%;
          }
        }
      }
    }
}
</style>
